import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BCStylesValueType } from "../models/types/BrandConfigValues.type";
import { brandGetAction } from "../redux/actions/brand.actions";
import { BrandState } from "../redux/reducers/brand.reducer";
import {
  brandConfigSelector,
  brandSelector,
} from "../redux/selectors/app.selectors";
import Utils from "../utils/Utils";
import { BrandBackgroundColor, BrandConfigKeys } from "../utils/enums";

// The values should match with variables in index.scss and tailwind.config.js
export const cssVars = {
  color: {
    BACKGROUND: "--color-background",
    TEXT_PRIMARY: "--color-text-primary",
    BUTTON_PRIMARY: "--color-button-primary",
    BUTTON_SECONDARY: "--color-button-secondary",
    TEXT_BUTTON: "--color-text-button",

    DASHBOARD: "--color-dashboard",
    CARD_PRIMARY: "--color-card-primary",
    MODAL_PRIMARY: "--color-modal-primary",
  },
  font: {
    PRIMARY: "--font-primary",
    SECONDARY: "--font-secondary",
    WEIGHT_PRIMARY: "--font-weight-primary",
    TRACKING_PRIMARY: "--font-tracking-primary",
  },
};

export const useSetColorVariables = () => {
  const [areCSSVarsSet, setAreCSSVarsSet] = useState<boolean>(false);

  const dispatch = useDispatch();

  const { brand, configEntities: brandConfig }: BrandState =
    useSelector(brandSelector);

  const brandConfigStyles: BCStylesValueType | undefined = brandConfigSelector(
    brandConfig,
    BrandConfigKeys.STYLES
  )?.value;

  useEffect(() => {
    if (!brand) {
      dispatch(brandGetAction());
    }
    if (brandConfigStyles) {
      // 1
      document?.documentElement?.style?.setProperty?.(
        cssVars.color.BACKGROUND,
        Utils.hexToRgb(brandConfigStyles.background_color)
      );

      // 2
      document?.documentElement?.style?.setProperty?.(
        cssVars.color.TEXT_PRIMARY,
        Utils.hexToRgb(
          // TODO: Color will come from BE
          brandConfigStyles.background_color === BrandBackgroundColor.BLACK
            ? "#ffffff"
            : "#000000"
        )
      );

      // 3
      document?.documentElement?.style?.setProperty?.(
        cssVars.color.BUTTON_PRIMARY,
        Utils.hexToRgb(brandConfigStyles.primary_color)
      );

      // 4
      document?.documentElement?.style?.setProperty?.(
        cssVars.color.BUTTON_SECONDARY,
        Utils.hexToRgb(brandConfigStyles.secondary_color)
      );

      // 5
      document?.documentElement?.style?.setProperty?.(
        cssVars.color.TEXT_BUTTON,
        Utils.hexToRgb(brandConfigStyles.button_font_color)
      );

      // 6
      document?.documentElement?.style?.setProperty?.(
        cssVars.font.PRIMARY,
        brandConfigStyles.primary_font
      );

      // 6B and 6C
      if (
        ["Thyga", "Thyga_Italic", "Blaster"].includes(
          brandConfigStyles.primary_font
        )
      ) {
        // Since these fonts are too heavy
        document?.documentElement?.style?.setProperty?.(
          cssVars.font.WEIGHT_PRIMARY,
          "normal"
        );

        document?.documentElement?.style?.setProperty?.(
          cssVars.font.TRACKING_PRIMARY,
          "0.05rem"
        );
      }

      // 7
      document?.documentElement?.style?.setProperty?.(
        cssVars.font.SECONDARY,
        brandConfigStyles.secondary_font
      );

      // 8
      document?.documentElement?.style?.setProperty?.(
        cssVars.color.DASHBOARD,
        Utils.hexToRgb(
          // TODO: Color will come from BE
          brandConfigStyles.background_color === BrandBackgroundColor.BLACK
            ? "#080808"
            : "#fafafa"
        )
      );

      // 9
      document?.documentElement?.style?.setProperty?.(
        cssVars.color.CARD_PRIMARY,
        Utils.hexToRgb(
          // TODO: Color will come from BE
          brandConfigStyles.background_color === BrandBackgroundColor.BLACK
            ? "#131414"
            : "#f3f3f3"
        )
      );

      // 10
      document?.documentElement?.style?.setProperty?.(
        cssVars.color.MODAL_PRIMARY,
        Utils.hexToRgb(
          // TODO: Color will come from BE
          brandConfigStyles.background_color === BrandBackgroundColor.BLACK
            ? "#15171B"
            : "#E7E7E7"
        )
      );

      setAreCSSVarsSet(true);
    }
  }, [brandConfigStyles, brand, dispatch]);

  return areCSSVarsSet;
};
