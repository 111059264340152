import { useRouter } from "next/router";
import {
  Chat,
  Crown,
  CurrencyCircleDollar,
  GearSix,
  Gift,
  Handbag,
  List,
  MegaphoneSimple,
  SignIn,
  TrendUp,
  Trophy,
  UsersThree,
} from "phosphor-react";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NextRouteConfig from "../../../../next-routes/NextRouteConfig";
import HomeIcon from "../../../../public/icons/home-menu-icon";
import { BrandContext } from "../../../contexts/BrandContext";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { Admin } from "../../../models/entities/Admin";
import { Brand } from "../../../models/entities/Brand";
import { User } from "../../../models/entities/User";
import {
  AdminDashboardItems,
  UserDashboardItems,
} from "../../../models/enums/DashboardItems";
import { ButtonClickPayloadGA } from "../../../models/types/GAPayload.types";
import { AppState } from "../../../redux/reducers";
import { BrandState } from "../../../redux/reducers/brand.reducer";
import {
  brandConfigSelector,
  brandSelector,
  userSelector,
} from "../../../redux/selectors/app.selectors";
import Utils from "../../../utils/Utils";
import { BrandConfigKeys, GAEventName, ModalType } from "../../../utils/enums";
import ConfirmDialog from "../Modal/ConfirmDialog";
import SupportModal from "../Modal/support-modal/SupportModal";
import UserDashboardDesktopView from "./user-dashboard-components/UserDashboardDesktopView";
import UserDashboardMobileView from "./user-dashboard-components/UserDashboardMobileView";

interface userDashBoardProps {}

export interface DashboardItemsType {
  label: string;
  value: string;
  isDisabled?: boolean;
  isHidden?: boolean;

  // If it's a normal link inside app
  defaultRoutePath?: string;
  regex?: RegExp;
  // OR
  onClick?: () => void;

  // If it should open a dropdown
  openDropdown?: boolean;
  dropdownItems?: DashboardItemsType[];

  // If the link is external to the app
  isExternal?: boolean;

  // Whether you want to open the link (internal/external) in new tab
  openInNewTab?: boolean;

  // CSS classes
  class?: string;

  icon?: React.ReactNode;

  // Mobile view order
  id?: number;
}

const UserDashboard: React.FC<userDashBoardProps> = (props) => {
  const { setOpenCookiePolicy } = useContext(BrandContext);
  const router = useRouter();
  const { adminData } = useSelector(userSelector);
  const [selectedMenu, setSelectedMenu] = useState<string>();
  const [collapsed, setCollapsed] = useState<boolean>(
    !!adminData ? true : false
  );
  const [isSupportModalOpen, setIsSupportModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<any>();

  useEffect(() => {
    setSelectedMenu(router.asPath);
  }, [router.asPath]);

  // Needed for disconnect modal, starting from here
  const [isDisconnectedModalOpen, setIsDisconnectedModalOpen] = useState(false);

  const disconnect = async () => {
    router.replace(NextRouteConfig.logout);
  };

  // Till here
  const { brand, configEntities: brandConfigs }: BrandState = useSelector(
    (state: AppState) => brandSelector(state)
  );

  const userState: { userData: User; loading: boolean; adminData: Admin } =
    useSelector((state: AppState) => userSelector(state));

  const [navBarItems, setNavbarItems] = useState<DashboardItemsType[]>([]);
  const handelBrandUserDashBoardConfigs = () => ({
    tiers: brandConfigSelector(brandConfigs, BrandConfigKeys.TIERS)?.value,
    tires_value: brandConfigSelector(brandConfigs, BrandConfigKeys.TIERS_VALUES)
      ?.value,
    suggestion_email: brandConfigSelector(brandConfigs, BrandConfigKeys.EMAIL)
      ?.value?.suggestions,
  });
  const brandUserDashBoardConfigs = handelBrandUserDashBoardConfigs();
  const isBrandRewardConfigEnabled = brandConfigSelector(
    brandConfigs,
    BrandConfigKeys.REWARDS_CONFIG
  )?.value?.is_enabled;
  const { isMobile } = useBreakpoint();
  const userNavbarItems = [
    {
      id: 3,
      label: "Home",
      value: UserDashboardItems.HOME,
      defaultRoutePath: NextRouteConfig.USER.HOME._root,
      regex: NextRouteConfig.USER.HOME.META.regex,
      icon: <HomeIcon className="w-6 h-6 p-[3px]" />,
    },
    {
      id: 1,
      label: "Rewards",
      value: UserDashboardItems.REWARDS,
      defaultRoutePath: NextRouteConfig.USER.REWARDS._root,
      regex: NextRouteConfig.USER.REWARDS.META.regex,
      icon: <Gift className="w-6 h-6" />,
    },
    {
      id: 2,
      label: "Exclusive Content",
      value: UserDashboardItems.EXCLUSIVE_CONTENT,
      defaultRoutePath: NextRouteConfig.USER.EXCLUSIVE_CONTENT._root,
      regex: NextRouteConfig.USER.EXCLUSIVE_CONTENT.META.regex,
      icon: <Crown className="w-6 h-6" />,
    },
    {
      id: 4,
      label: "Achievements",
      value: UserDashboardItems.ACHIEVEMENTS,
      defaultRoutePath: NextRouteConfig.USER.ACHIEVEMENTS._root,
      regex: NextRouteConfig.USER.ACHIEVEMENTS.META.regex,
      icon: <Trophy className="w-6 h-6" />,
    },
    {
      id: 6,
      label: "Profile",
      value: UserDashboardItems.SETTINGS,
      defaultRoutePath: NextRouteConfig.USER.SETTINGS._root,
      regex: NextRouteConfig.USER.SETTINGS.META.regex,
      isHidden: isMobile,
      icon: <GearSix className="w-6 h-6" />,
    },
    {
      id: 5,
      label: "More",
      value: "more",
      icon: <List className="w-6 h-6" />,
      openDropdown: true,
      dropdownItems: [
        {
          label: "Support",
          value: UserDashboardItems.SUPPORT,
          isDisabled: !brandUserDashBoardConfigs.suggestion_email,
          onClick: () => {
            setIsSupportModalOpen(true);
            setModalType(ModalType.SUPPORT);
            Utils.GAEvent<ButtonClickPayloadGA>(GAEventName.BUTTON_CLICK, {
              event_category: "Button",
              event_label: "Open Support",
            });
          },
        },
        {
          label: "Suggestion Box",
          value: UserDashboardItems.SUGGESTION_BOX,
          isDisabled: !brandUserDashBoardConfigs.suggestion_email,
          onClick: () => {
            setIsSupportModalOpen(true);
            setModalType(ModalType.SUGGESTIONS);
            Utils.GAEvent<ButtonClickPayloadGA>(GAEventName.BUTTON_CLICK, {
              event_category: "Button",
              event_label: "Open Suggestion Box",
            });
          },
        },
        {
          label: "Privacy Policy",
          value: UserDashboardItems.PRIVACY_POLICY,
          defaultRoutePath: brand?.privacy_policy_url,
          isHidden: !brand?.privacy_policy_url,
          openInNewTab: true,
        },
        {
          label: "Cookie Policy",
          value: UserDashboardItems.COOKIE_POLICY,
          onClick: () => {
            setOpenCookiePolicy?.(true);
          },
          isHidden: !setOpenCookiePolicy,
        },
        {
          label: "Terms & Conditions",
          value: UserDashboardItems.TERMS_AND_CONDITIONS,
          defaultRoutePath: brand?.terms_and_condition_url,
          isHidden: !brand?.terms_and_condition_url,
          openInNewTab: true,
        },
        {
          label: "Profile",
          value: UserDashboardItems.SETTINGS,
          defaultRoutePath: NextRouteConfig.USER.SETTINGS._root,
          regex: NextRouteConfig.USER.SETTINGS.META.regex,
          isHidden: !isMobile,
          icon: <GearSix className="w-4 h-4" />,
        },
      ],
    },
  ];
  const adminNavbarItems = [
    {
      id: 3,
      label: "Campaigns",
      value: AdminDashboardItems.CAMPAIGNS,
      defaultRoutePath: NextRouteConfig.ADMIN.CAMPAIGNS._root,
      regex: NextRouteConfig.ADMIN.CAMPAIGNS.META.regex,
      icon: <MegaphoneSimple className="w-6 h-6" />,
    },
    {
      id: 1,
      label: "Rewards",
      value: AdminDashboardItems.REWARDS,
      defaultRoutePath: NextRouteConfig.ADMIN.REWARDS._root,
      regex: NextRouteConfig.ADMIN.REWARDS.META.regex,
      icon: <Handbag className="w-6 h-6" />,
    },
    {
      id: 2,
      label: "Exclusive Content",
      value: AdminDashboardItems.EXCLUSIVE_CONTENT,
      defaultRoutePath: NextRouteConfig.ADMIN.EXCLUSIVE_CONTENT._root,
      regex: NextRouteConfig.ADMIN.EXCLUSIVE_CONTENT.META.regex,
      icon: <Crown className="w-6 h-6" />,
    },
    {
      id: 8,
      label: "Analytics",
      value: AdminDashboardItems.ANALYTICS,
      defaultRoutePath: NextRouteConfig.ADMIN.ANALYTICS._root,
      isHidden: isMobile,
      regex: NextRouteConfig.ADMIN.ANALYTICS.META.regex,
      icon: <TrendUp className="w-6 h-6" />,
    },
    {
      id: 7,
      label: "Transactions",
      value: AdminDashboardItems.TRANSACTIONS,
      isHidden: isMobile,
      defaultRoutePath: NextRouteConfig.ADMIN.TRANSACTIONS._root,
      regex: NextRouteConfig.ADMIN.TRANSACTIONS.META.regex,
      icon: <CurrencyCircleDollar className="w-6 h-6" />,
    },
    {
      id: 6,
      label: "Members",
      value: AdminDashboardItems.MEMBERS,
      isHidden: isMobile,
      defaultRoutePath: NextRouteConfig.ADMIN.MEMBERS._root,
      regex: NextRouteConfig.ADMIN.MEMBERS.META.regex,
      icon: <UsersThree className="w-6 h-6" />,
    },
    {
      id: 4,
      label: "Settings",
      value: AdminDashboardItems.SETTINGS,
      defaultRoutePath: NextRouteConfig.ADMIN.SETTINGS._root,
      regex: NextRouteConfig.ADMIN.SETTINGS.META.regex,
      icon: <GearSix className="w-6 h-6" />,
    },
    {
      id: 5,
      label: "More",
      value: "more",
      icon: <List className="w-6 h-6" />,
      openDropdown: true,
      dropdownItems: [
        {
          label: "Privacy Policy",
          value: UserDashboardItems.PRIVACY_POLICY,
          defaultRoutePath: brand?.privacy_policy_url,
          isHidden: !brand?.privacy_policy_url,
          openInNewTab: true,
        },
        {
          label: "Cookie Policy",
          value: UserDashboardItems.COOKIE_POLICY,
          onClick: () => {
            setOpenCookiePolicy?.(true);
          },
          isHidden: !setOpenCookiePolicy,
        },
        {
          label: "Terms & Conditions",
          value: UserDashboardItems.TERMS_AND_CONDITIONS,
          defaultRoutePath: brand?.terms_and_condition_url,
          isHidden: !brand?.terms_and_condition_url,
          openInNewTab: true,
        },
        {
          label: "Transactions",
          value: AdminDashboardItems.TRANSACTIONS,
          isHidden: !isMobile,
          defaultRoutePath: NextRouteConfig.ADMIN.TRANSACTIONS._root,
          regex: NextRouteConfig.ADMIN.TRANSACTIONS.META.regex,
          icon: <CurrencyCircleDollar className="w-6 h-6" />,
        },
        {
          label: "Members",
          value: AdminDashboardItems.MEMBERS,
          isHidden: !isMobile,
          defaultRoutePath: NextRouteConfig.ADMIN.MEMBERS._root,
          regex: NextRouteConfig.ADMIN.MEMBERS.META.regex,
          icon: <UsersThree className="w-6 h-6" />,
        },
        {
          label: "Analytics",
          value: AdminDashboardItems.ANALYTICS,
          defaultRoutePath: NextRouteConfig.ADMIN.ANALYTICS._root,
          isHidden: !isMobile,
          regex: NextRouteConfig.ADMIN.ANALYTICS.META.regex,
          icon: <TrendUp className="w-4 h-4" />,
        },
      ],
    },
  ];

  useEffect(() => {
    // If wallet connected
    if (userState?.userData) {
      setNavbarItems(
        isBrandRewardConfigEnabled
          ? userNavbarItems
          : userNavbarItems.filter(
              (item) => item.value !== UserDashboardItems.REWARDS
            )
      );
    } else if (userState?.adminData) {
      // TODO: To update after design update
      setNavbarItems(adminNavbarItems);
    } else {
      setCollapsed(false);
      setNavbarItems(
        isMobile
          ? [
              {
                id: 1,
                label: "Get In Touch",
                value: UserDashboardItems.GET_IN_TOUCH,
                isDisabled: true,
                icon: <Chat weight="bold" className="w-6 h-6" />,
              },
              {
                id: 2,
                label: "Log In",
                value: UserDashboardItems.LOG_IN,
                defaultRoutePath: NextRouteConfig.login,
                icon: <SignIn weight="bold" className="w-6 h-6" />,
              },
              {
                id: 3,
                label: "More",
                value: "more",
                icon: <List className="w-6 h-6" />,
                openDropdown: true,
                dropdownItems: [
                  {
                    label: "Privacy Policy",
                    value: UserDashboardItems.PRIVACY_POLICY,
                    defaultRoutePath: brand?.privacy_policy_url,
                    isHidden: !brand?.privacy_policy_url,
                    openInNewTab: true,
                  },
                  {
                    label: "Cookie Policy",
                    value: UserDashboardItems.COOKIE_POLICY,
                    onClick: () => {
                      setOpenCookiePolicy?.(true);
                    },
                    isHidden: !setOpenCookiePolicy,
                  },
                  {
                    label: "Terms & Conditions",
                    value: UserDashboardItems.TERMS_AND_CONDITIONS,
                    defaultRoutePath: brand?.terms_and_condition_url,
                    isHidden: !brand?.terms_and_condition_url,
                    openInNewTab: true,
                  },
                ],
              },
            ]
          : []
      );
    }
  }, [
    brandUserDashBoardConfigs?.suggestion_email,
    setOpenCookiePolicy,
    userState?.userData,
    userState?.adminData,
    brand?.privacy_policy_url,
    brand?.terms_and_condition_url,
    isMobile,
  ]);

  return (
    <>
      <SupportModal
        isShareModalOpen={isSupportModalOpen}
        setIsShareModalOpen={setIsSupportModalOpen}
        modalType={modalType}
        setModalType={setModalType}
      />
      <UserDashboardDesktopView
        collapsed={collapsed}
        isAdmin={!!userState.adminData}
        isUser={!!userState.userData}
        setCollapsed={setCollapsed}
        userData={userState.userData}
        brandUserDashBoardConfigs={brandUserDashBoardConfigs}
        selectedMenu={selectedMenu as unknown as string}
        navBarItems={navBarItems}
        setModalType={setModalType}
        setIsSupportModalOpen={setIsSupportModalOpen}
        setIsDisconnectedModalOpen={setIsDisconnectedModalOpen}
        brand={brand as unknown as Brand}
      />
      {/* Mobile View */}
      <UserDashboardMobileView
        navBarItems={navBarItems}
        selectedMenu={selectedMenu as unknown as string}
        collapsed={collapsed}
        brandUserDashBoardConfigs={brandUserDashBoardConfigs}
        setIsDisconnectedModalOpen={setIsDisconnectedModalOpen}
        setIsSupportModalOpen={setIsSupportModalOpen}
        isUser={!!userState.userData}
        setModalType={setModalType}
      />
      <ConfirmDialog
        showModal={isDisconnectedModalOpen}
        setShowModal={setIsDisconnectedModalOpen}
        confirmButtonText={"Log Out"}
        confirmButtonOnClick={() => {
          disconnect();
        }}
        modalHeaderText={"Are you sure you want to log out"}
        modalDescriptionText={
          <span className="whitespace-pre-line">
            {
              "Logging out will end your current session. Stay secure. \n See you soon!👋 "
            }
          </span>
        }
      />
    </>
  );
};

export default React.memo(UserDashboard);
