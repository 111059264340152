import { initializeApp } from "firebase/app";
import { getId, getInstallations } from "firebase/installations";
import { deleteToken, getMessaging, Messaging } from "firebase/messaging";
import {
  ENV_FIREBASE_API_KEY,
  ENV_FIREBASE_APP_ID,
  ENV_FIREBASE_AUTH_DOMAIN,
  ENV_FIREBASE_MEASUREMENT_ID,
  ENV_FIREBASE_MESSAGING_SENDER_ID,
  ENV_FIREBASE_PROJECT_ID,
  ENV_FIREBASE_STORAGE_BUCKET,
} from "../../constants/secret";

const firebaseApp = initializeApp({
  apiKey: ENV_FIREBASE_API_KEY,
  authDomain: ENV_FIREBASE_AUTH_DOMAIN,
  projectId: ENV_FIREBASE_PROJECT_ID,
  storageBucket: ENV_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: ENV_FIREBASE_MESSAGING_SENDER_ID,
  appId: ENV_FIREBASE_APP_ID,
  measurementId: ENV_FIREBASE_MEASUREMENT_ID || undefined,
});

export const messaging = () => getMessaging(firebaseApp);

export const firebaseDeviceId = () => getId(getInstallations());

export const deleteMessagingToken = (messaging: Messaging) =>
  deleteToken(messaging);

export default firebaseApp;
