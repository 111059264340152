import Image from "next/image";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ToastContainerIds } from "../constants/constants";
import useDeleteStaleFCMToken from "../hooks/fcm/useDeleteStaleFCMToken";
import useFcmToken from "../hooks/fcm/useFcmToken";
import useForegroundPushNotification from "../hooks/fcm/useForegroundPushNotification";
import useSendTokenToServer from "../hooks/fcm/useSendTokenToServer";
import { AuthState } from "../redux/reducers/auth.reducer";
import { BrandState } from "../redux/reducers/brand.reducer";
import { UserState } from "../redux/reducers/user.reducer";
import {
  authSelector,
  brandSelector,
  userSelector
} from "../redux/selectors/app.selectors";

interface FirebaseCloudMessagingSetupProps {}

const FirebaseCloudMessagingSetup: React.FC<
  FirebaseCloudMessagingSetupProps
> = () => {
  const { brand }: BrandState = useSelector(brandSelector);
  const { userID }: AuthState = useSelector(authSelector);
  const { adminData }: UserState = useSelector(userSelector);

  //   ---FCM Setup starts here---
  //   If user is logged out, but fcm_token is present. Delete that stale/inactive token.
  useDeleteStaleFCMToken();

  const showNotifPermissionToast = useCallback((retrieveTokenCallback) => {
    toast(
      <div className="flex justify-between mr-3 text-center w-fit ">
        <div>Allow notifications?</div>
      </div>,
      {
        closeOnClick: false,
        className:
          "relative mx-auto mb-2 flex px-4 !w-fit rounded-xl text-sm !font-secondary justify-between overflow-hidden cursor-pointer bg-primaryModal/90 backdrop-blur-md text-primaryText border border-primaryText/10",
        closeButton: ({ closeToast }) => (
          <div className="flex items-center justify-center space-x-2">
            <button onClick={() => {
              retrieveTokenCallback()
              closeToast()
            }} className="text-brandGreen">
              Definitely✅
            </button>
            <button
              onClick={() => {
                closeToast();
              }}
              className="whitespace-nowrap text-brandError"
            >
              Nope❌
            </button>
          </div>
        ),
        autoClose: false,
        hideProgressBar: true,
        position: "bottom-center",
        toastId: ToastContainerIds.PUSH_NOTIFICATIONS_PROMPT_TOAST
      }
    );
  }, []);

  // Generate Token
  const { fcmToken, device_id } = useFcmToken(
    brand?.id,
    userID,
    showNotifPermissionToast
  );

  // Send token to server, subscribe to topics etc.
  useSendTokenToServer(fcmToken, device_id, userID);

  const showToastOnMessage = useCallback(
    (payload) => (
      <div className="flex items-start gap-3">
        {payload.notification?.icon ? (
          <Image
            src={payload.notification?.icon}
            alt="Notification Icon"
            width={64}
            height={64}
            className="rounded-md"
          />
        ) : null}
        <div className="flex flex-col">
          <h1 className="font-bold">{payload?.notification?.title}</h1>
          <h6 className="text-xs">{payload?.notification?.body}</h6>
        </div>
      </div>
    ),
    []
  );

  // Setup Foreground Message Handler
  useForegroundPushNotification(showToastOnMessage, userID, !!adminData);
  //   ---FCM Setup ends here---

  return <></>;
};

export default React.memo(FirebaseCloudMessagingSetup);
