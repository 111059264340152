import { all, call, put, takeLatest } from "redux-saga/effects";
import { Engagement } from "../../models/entities/Engagement";
import { SAGA_PAYLOAD_TYPE } from "../../models/types/SagaPayloadType";
import { webToastService } from "../../services/WebToastService";
import {
  ENGAGEMENT_CREATE,
  ENGAGEMENT_LIST_GET,
  ENGAGEMENT_TOGGLE_ARCHIVE,
  ENGAGEMENT_UPDATE,
} from "../actions/actions.constants";
import {
  engagementCreateCompleteAction,
  engagementCreateErrorAction,
  engagementListGetCompletedAction,
  engagementListGetError,
  engagementToggleCompletedAction,
  engagementToggleErrorAction,
  engagementUpdateCompletedAction,
  engagementUpdateErrorAction,
  userEngagementListGetAction,
} from "../actions/user.engagement.action";
import { engagementService } from "../../services/api-services/EngagementService";

function* engagementListSaga(data: SAGA_PAYLOAD_TYPE) {
  const payload = data.payload;
  try {
    const response: {
      engagement: {
        twitter: Engagement[];
        discord: Engagement[];
        instagram: Engagement[];
        youtube: Engagement[];
        tiktok: Engagement[];
        gmail: Engagement[];
        other: Engagement[];
      };
    } = yield call(engagementService.list, {
      filter: payload?.req?.filter,
      sort_by: payload?.req?.sort_by,
      search: payload?.req?.search,
    });
    yield put(
      engagementListGetCompletedAction({
        res: {
          engagement: response.engagement,
        },
      })
    );
  } catch (e: any) {
    yield put(
      engagementListGetError({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* engagementCreateSaga(data: SAGA_PAYLOAD_TYPE) {
  const payload = data.payload;
  try {
    const response: {
      engagement: Engagement;
      isPushNotificationNotSent?: boolean;
    } = yield call(engagementService.create, payload.req);
    yield put(
      engagementCreateCompleteAction({
        res: { engagement: response.engagement },
      })
    );

    if (response?.isPushNotificationNotSent) {
      webToastService.showWarning(
        "Successfully created but push notifications not sent."
      );
    } else {
      webToastService.showSuccess("Engagement has been created");
    }
  } catch (e: any) {
    yield put(engagementCreateErrorAction({ message: e?.message }));
  }
}

function* engagementEditSaga(data: SAGA_PAYLOAD_TYPE) {
  const payload = data.payload;
  try {
    const response: {
      engagement: Engagement;
      isPushNotificationNotSent?: boolean;
    } = yield call(engagementService.edit, payload.req);
    yield put(
      engagementUpdateCompletedAction({
        res: { engagement: response.engagement },
      })
    );

    if (response?.isPushNotificationNotSent) {
      webToastService.showWarning(
        "Successfully updated but push notifications not sent."
      );
    } else {
      webToastService.showSuccess("Engagement is updated successfully!!");
    }
  } catch (e: any) {
    yield put(engagementUpdateErrorAction({ message: e?.message }));
  }
}
function* engagementShowSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(
      engagementService.toggleArchive,
      payload.req.id
    );
    yield put(
      engagementToggleCompletedAction({
        res: { engagements: response.engagements },
      })
    );
    webToastService.showSuccess(response);
  } catch (e: any) {
    const message = (e?.errors && e.errors[0]?.message) || e?.message;
    yield put(engagementToggleErrorAction({ message }));
  }
}

function* userEngagementSaga() {
  yield all([
    takeLatest(ENGAGEMENT_LIST_GET, engagementListSaga),
    takeLatest(ENGAGEMENT_CREATE, engagementCreateSaga),
    takeLatest(ENGAGEMENT_UPDATE, engagementEditSaga),
    takeLatest(ENGAGEMENT_TOGGLE_ARCHIVE, engagementShowSaga),
  ]);
}

export default userEngagementSaga;
