import { all, call, put, takeLatest } from "redux-saga/effects";
import { SAGA_PAYLOAD_TYPE } from "../../models/types/SagaPayloadType";
import { webToastService } from "../../services/WebToastService";
import { baseApiService } from "../../services/api-services/BaseApiService";
import { brandService } from "../../services/api-services/BrandService";
import {
  ADD_ADMIN_WALLET_ADDRESS,
  ADMIN_LIST_GET,
  ADMIN_WALLET_ADDRESS_DELETE,
  BRAND_BANNER_UPDATE,
  BRAND_CONFIG_UPDATE,
  BRAND_CONFIG_UPLOAD_ALLOW_LIST,
  BRAND_FAVICON_UPDATE,
  BRAND_GET,
  BRAND_LATEST_TWEET_LIST_GET,
  BRAND_LOGO_UPDATE,
  BRAND_PWA_APPEARANCE_CONFIG_ACTION,
  BRAND_SHARE_IMAGE_UPDATE,
  BRAND_SOCIALS_CREDENTIALS_GET,
  BRAND_SOCIALS_CREDENTIALS_UPDATE,
  BRAND_UPDATE,
  EDIT_ADMIN,
} from "../actions/actions.constants";
import {
  addAdminWalletAddressCompletedAction,
  addAdminWalletAddressErrorAction,
  adminListGetCompletedAction,
  adminListGetErrorAction,
  adminWalletAddressDeleteCompletedAction,
  adminWalletAddressDeleteErrorAction,
  brandBannerUpdateCompletedAction,
  brandBannerUpdateErrorAction,
  brandConfigUpdateCompletedAction,
  brandConfigUpdateErrorAction,
  brandFaviconUpdateCompletedAction,
  brandFaviconUpdateErrorAction,
  brandGetCompletedAction,
  brandGetErrorAction,
  brandLatestTweetListGetCompletedAction,
  brandLatestTweetListGetErrorAction,
  brandLogoUpdateCompletedAction,
  brandLogoUpdateErrorAction,
  brandShareImageUpdateCompletedAction,
  brandShareImageUpdateErrorAction,
  brandSocialsCredentialsGetCompletedAction,
  brandSocialsCredentialsUpdateCompletedAction,
  brandSocialsCredentialsUpdateErrorAction,
  brandUpdateCompletedAction,
  brandUpdateErrorAction,
  editAdminCompletedAction,
  editAdminErrorAction,
  getPWAManifestDataCompleted,
} from "../actions/brand.actions";

function* brandGetSaga(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(brandService.list);
    yield put(brandGetCompletedAction({ res: { brand: response.brand } }));
  } catch (e: any) {
    yield put(
      brandGetErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* brandUpdateSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(brandService.update, payload.id, {
      title: payload.req.title,
      privacy_policy: payload.req.privacy_policy_url,
      terms: payload.req.terms_and_condition_url,
      currency: payload.req.currency,
      website_url: payload.req.website_url,
    });
    webToastService.showSuccess("Brand is updated Successfully");
    yield put(brandUpdateCompletedAction({ res: { brand: response.brand } }));
  } catch (e: any) {
    yield put(
      brandUpdateErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* brandUpdateLogoSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(brandService.updateLogo, payload.id);
    yield call(
      baseApiService.uploadFileToS3UsingObjectURL,
      payload.req.logo_image_object_url,
      response.logo_signed_url
    );
    webToastService.showSuccess("Logo is updated Successfully");
    yield put(
      brandLogoUpdateCompletedAction({
        res: { brand_config: response.brand_config },
      })
    );
  } catch (e: any) {
    webToastService.showError(
      (e?.errors && e.errors[0]?.message) || e?.message
    );
    yield put(
      brandLogoUpdateErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}
function* brandUpdateShareImageSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(brandService.updateShareImage, payload.id);
    yield call(
      baseApiService.uploadFileToS3UsingObjectURL,
      payload.req.share_image_object_url,
      response.shareable_signed_url
    );
    webToastService.showSuccess("Share Image is updated Successfully");
    yield put(
      brandShareImageUpdateCompletedAction({
        res: { brand_config: response.brand_config },
      })
    );
  } catch (e: any) {
    webToastService.showError(
      (e?.errors && e.errors[0]?.message) || e?.message
    );
    yield put(
      brandShareImageUpdateErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}
function* brandUpdateFaviconSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(brandService.updateFavicon, payload.id);
    yield call(
      baseApiService.uploadFileToS3UsingObjectURL,
      payload.req.favicon_image_object_url,
      response.favicon_signed_url
    );
    webToastService.showSuccess("Favicon is updated Successfully");
    yield put(
      brandFaviconUpdateCompletedAction({
        res: { brand_config: response.brand_config },
      })
    );
  } catch (e: any) {
    webToastService.showError(
      (e?.errors && e.errors[0]?.message) || e?.message
    );
    yield put(
      brandFaviconUpdateErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* brandUpdateBannerSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(brandService.updateBanner, payload.id);
    yield call(
      baseApiService.uploadFileToS3UsingObjectURL,
      payload.req.banner_image_object_url,
      response.banner_signed_url
    );
    webToastService.showSuccess("Banner is updated Successfully");
    yield put(
      brandBannerUpdateCompletedAction({
        res: { brand_config: response.brand_config },
      })
    );
  } catch (e: any) {
    webToastService.showError(
      (e?.errors && e.errors[0]?.message) || e?.message
    );
    yield put(
      brandBannerUpdateErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}
function* addAdmin(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(brandService.addAdmin, payload.req);
    yield put(
      addAdminWalletAddressCompletedAction({
        res: {
          admin: response.admin,
        },
      })
    );
    webToastService.showSuccess("Admin has been added successfully.");
  } catch (e: any) {
    webToastService.showError(
      (e?.errors && e.errors[0]?.message) || e?.message
    );
    yield put(
      addAdminWalletAddressErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}
function* editAdmin(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(brandService.editAdmin, {
      id: payload.id,
      payload: payload.req,
    });
    yield put(
      editAdminCompletedAction({
        res: {
          admin: response.admin,
        },
      })
    );
    webToastService.showSuccess("Admin has been edited successfully.");
  } catch (e: any) {
    webToastService.showError(
      (e?.errors && e.errors[0]?.message) || e?.message
    );
    yield put(
      editAdminErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}
function* brandSocialsCredentialsGetSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(brandService.getBrandSocialCredentials);
    yield put(
      brandSocialsCredentialsGetCompletedAction({
        res: {
          brand_social_credentials: response.brand_social_credentials,
        },
      })
    );
  } catch (e: any) {
    yield put(
      brandSocialsCredentialsUpdateErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* brandSocialsCredentialsUpdateSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(brandService.updateBrandSocialCredentials, {
      ...payload.req,
    });
    yield put(
      brandSocialsCredentialsUpdateCompletedAction({
        res: {
          brand_social_credential: response.brand_social_credential,
        },
      })
    );
    webToastService.showSuccess("Social credential added successfully");
  } catch (e: any) {
    yield put(
      brandSocialsCredentialsUpdateErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* getAdminListSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(brandService.getAdminList, {
      search: payload.req.search,
    });
    yield put(
      adminListGetCompletedAction({
        res: {
          admins: response.admins,
        },
      })
    );
  } catch (e: any) {
    yield put(
      adminListGetErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* getAdminWalletAddressDeleteSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(brandService.deleteAdmin, payload?.req?.id);
    if (!!response.success) {
      yield put(
        adminWalletAddressDeleteCompletedAction({
          id: payload.req.id,
        })
      );
      webToastService.showSuccess("Admin is deleted successfully.");
    }
  } catch (e: any) {
    webToastService.showError(
      (e?.errors && e.errors[0]?.message) || e?.message
    );
    yield put(
      adminWalletAddressDeleteErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}
function* getBrandLatestTweetSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(brandService.getBrandLatestTweet, {
      urls: payload.req.urls,
    });
    yield put(
      brandLatestTweetListGetCompletedAction({
        res: {
          tweets: response.engagements,
        },
      })
    );
    if (response.engagements?.length) {
      webToastService.showSuccess("Latest tweets added!!");
    } else {
      webToastService.showSuccess("No new tweets available.");
    }
  } catch (e: any) {
    yield put(
      brandLatestTweetListGetErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* updateBrandConfigSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(brandService.updateConfig, {
      id: payload.req.config_id,
      value: payload.req.value,
    });
    yield put(
      brandConfigUpdateCompletedAction({
        res: {
          config: response.brand_config,
        },
      })
    );
    webToastService.showSuccess("Brand Config updated!!");
  } catch (e: any) {
    yield put(
      brandConfigUpdateErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}
function* brandConfigUploadAllowlistSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(brandService.uploadAllowlist, {
      file: payload.req.file,
      config: payload.req.configKey,
    });
    if (response.success) {
      webToastService.showSuccess("Allowlist uploaded successfully!!");
    }
  } catch (e: any) {
    yield put(
      brandConfigUpdateErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* brandPwaAppearanceConfigSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;

  try {
    const response = yield call(brandService.pwaAppearanceConfig, {
      ...payload.req,
    });

    yield put(
      brandConfigUpdateCompletedAction({
        res: {
          config: response.updated_config,
          pwaDataLoading: true,
        },
      })
    );
    if (response?.updated_config?.value?.manifest_url) {
      const data = yield call(brandService.getManifestJSON, {
        url: response?.updated_config?.value?.manifest_url,
      });
      yield put(getPWAManifestDataCompleted({ data: data?.manifest_json }));
    }
    webToastService.showSuccess("Config Updated successfully");
  } catch (e: any) {
    yield put(
      brandConfigUpdateErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* brandSaga() {
  yield all([
    takeLatest(BRAND_UPDATE, brandUpdateSaga),
    takeLatest(BRAND_LOGO_UPDATE, brandUpdateLogoSaga),
    takeLatest(BRAND_BANNER_UPDATE, brandUpdateBannerSaga),
    takeLatest(BRAND_GET, brandGetSaga),
    takeLatest(ADD_ADMIN_WALLET_ADDRESS, addAdmin),
    takeLatest(BRAND_SOCIALS_CREDENTIALS_GET, brandSocialsCredentialsGetSaga),
    takeLatest(
      BRAND_SOCIALS_CREDENTIALS_UPDATE,
      brandSocialsCredentialsUpdateSaga
    ),
    takeLatest(ADMIN_LIST_GET, getAdminListSaga),
    takeLatest(ADMIN_WALLET_ADDRESS_DELETE, getAdminWalletAddressDeleteSaga),
    takeLatest(BRAND_LATEST_TWEET_LIST_GET, getBrandLatestTweetSaga),
    takeLatest(BRAND_CONFIG_UPDATE, updateBrandConfigSaga),
    takeLatest(BRAND_SHARE_IMAGE_UPDATE, brandUpdateShareImageSaga),
    takeLatest(BRAND_FAVICON_UPDATE, brandUpdateFaviconSaga),
    takeLatest(BRAND_CONFIG_UPLOAD_ALLOW_LIST, brandConfigUploadAllowlistSaga),
    takeLatest(EDIT_ADMIN, editAdmin),
    takeLatest(
      BRAND_PWA_APPEARANCE_CONFIG_ACTION,
      brandPwaAppearanceConfigSaga
    ),
  ]);
}

export default brandSaga;
