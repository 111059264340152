import { webStorageService } from "./WebStorageService";
import { baseApiService } from "./api-services/BaseApiService";

export class PushNotificationServices {
  private static _instance: PushNotificationServices;

  private constructor() {}

  static getInstance(): PushNotificationServices {
    if (!this._instance) {
      this._instance = new PushNotificationServices();
    }
    return this._instance;
  }

  async sendToServer(payload: {
    fcm_token: string;
    device_id: string;
  }): Promise<{ success: boolean }> {
    const authToken = await webStorageService.getAuthToken();
    return await baseApiService.post(
      "fcm/send-to-server",
      { fcm_token: payload.fcm_token, device_id: payload.device_id },
      {
        extras: { useAuth: authToken ? !!authToken : false },
      }
    );
  }

  async deleteFromServer(payload: {
    fcm_token: string;
  }): Promise<{ success: boolean }> {
    const authToken = await webStorageService.getAuthToken();
    return await baseApiService.delete("fcm/delete-from-server", {
      params: { fcm_token: payload.fcm_token },
      extras: { useAuth: authToken ? !!authToken : false },
    });
  }
}

export const pushNotificationService = PushNotificationServices.getInstance();
