import classNames from "classnames";
import React, {
  CSSProperties,
  FocusEvent,
  FunctionComponent,
  KeyboardEventHandler,
} from "react";
import { BrandContext } from "../../../../contexts/BrandContext";
import useId from "../../../../hooks/useId";
import withFormikContext from "../../withFormikContext";
import InputLabel from "../InputLabel/InputLabel";
import InputHelper from "../Inputhelper/InputHelper";

interface BrandTextAreaProps {
  name?: string;
  onChange: (event: any) => void;
  onBlur?: (event: FocusEvent) => void;
  onClick?: (event: any) => void;
  placeholder?: string;
  error?: string;
  label?: string;
  helperText?: string;
  className?: string;
  placeholderColor?: string;
  labelClassName?: string;
  style?: CSSProperties;
  disabled?: boolean;
  tooltip?: string;
  onKeyPress?: (event: any) => void;
  labelColor?: string;
  borderClassName?: string;
  onTouched?: Function;
  cols: number;
  onkeydown?: KeyboardEventHandler<HTMLTextAreaElement>;
  rows: number;
}

const BrandTextArea: FunctionComponent<BrandTextAreaProps & Partial<any>> = ({
  name,
  value,
  onBlur,
  onChange,
  onClick,
  placeholder,
  label,
  helperText,
  leadingIcon,
  error,
  className,
  labelClassName,
  style,
  disabled = false,
  tooltip,
  onKeyPress,
  borderClassName,
  cols,
  rows,
  onkeydown,
  placeholderColor = "brandSecondary",
  labelColor = "brandSecondary",
}) => {
  const id = useId();
  const brandValue = React.useContext(BrandContext);

  return (
    <span className="relative">
      {label && (
        <InputLabel
          htmlFor={id}
          label={label}
          className={classNames(`text-xs sm:text-sm ${labelClassName}`)}
        />
      )}

      <div className={` ${label ? "mt-2" : ""} relative rounded-md `}>
        <textarea
          name={name}
          id={id}
          cols={cols}
          rows={rows}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onClick={onClick}
          disabled={disabled}
          placeholder={placeholder}
          className={classNames(
            `block bg-transparent rounded-xl w-full transition ring-1 hover:ring-2 ring-primaryText/50 hover:ring-primaryText focus:ring-primaryText placeholder-brandGray/75 border-0 duration-300 focus:ring-1 focus:outline-none py-3 md:py-4 px-5 peer`,
            {
              "pl-12": !!leadingIcon,
              "opacity-50 pointer-events-none": !!disabled,
              "!placeholder-brandError !ring-brandError focus:!ring-brandError hover:!ring-brandError":
                !!error,
            },
            className
          )}
          onKeyPress={(event) => {
            if (event.which === 13 && onKeyPress) {
              onKeyPress(event);
            }
          }}
          onKeyDown={onkeydown}
          style={{
            ...style,
          }}
        />
      </div>
      <InputHelper type={"helper"} text={error ? undefined : helperText} />
      <InputHelper type={"error"} text={error} />
    </span>
  );
};

export default withFormikContext(BrandTextArea);

export const BrandInputWithoutFormik = BrandTextArea;
