import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useAccount, useDisconnect, useSignMessage } from "wagmi";
import {
  authVerifySignatureAction,
  authWalletAddressRejectSignAction,
} from "../../../redux/actions/auth.actions";
import {
  addWalletAddressRejectSignAction,
  addWalletAddressVerifySignAction,
} from "../../../redux/actions/user.actions";
import { AppState } from "../../../redux/reducers";
import { BrandState } from "../../../redux/reducers/brand.reducer";
import { UserState } from "../../../redux/reducers/user.reducer";
import {
  brandConfigSelector,
  brandSelector,
  userSelector,
} from "../../../redux/selectors/app.selectors";
import {
  INVITE_ID,
  RECAPTCHA_TOKEN,
  REFERRAL_CODE,
  webStorageService,
} from "../../../services/WebStorageService";
import { webToastService } from "../../../services/WebToastService";
import { authService } from "../../../services/api-services/AuthService";
import { userService } from "../../../services/api-services/UserService";
import { BrandConfigKeys } from "../../../utils/enums";

interface WalletAccountHookContainerProps {
  children?: any;

  // Store injected actions
  web3login?: any;
  addWalletAddressVerify?: any;

  // Store injected states
  userState?: UserState;
}

const WalletAccountHookContainer: React.FC<WalletAccountHookContainerProps> = ({
  children,
  web3login,
  userState,
}) => {
  const dispatch = useDispatch();
  const { configEntities: brandConfig }: BrandState =
    useSelector(brandSelector);

  const loginConfigs = {
    recaptcha: brandConfigSelector(
      brandConfig,
      BrandConfigKeys.RECAPTCHA_CONFIG
    )?.value,
  };

  const { signMessageAsync } = useSignMessage();

  const disconnect = useDisconnect();

  const account = useAccount({
    onDisconnect() {
      console.log("Disconnected from wallet.");

      // If disconnected from wallet and not already on log out page
      // if (!router.pathname.match(matchPagePathRegex(NextRouteConfig.logout)))
      //   router.replace(NextRouteConfig.logout);
    },
    async onConnect({ address }) {
      if (!address) {
        webToastService.showError("Something went wrong - Please try again.");
        return;
      }
      // If not already logged in
      if (!(await webStorageService.getAuthToken())) {
        try {
          const invite_id = webStorageService.getValue(INVITE_ID);
          const referral_code = webStorageService.getValue(REFERRAL_CODE);
          const reCaptcha_token = webStorageService.getValue(RECAPTCHA_TOKEN);
          const data = await authService.login({
            address,
            invite_id,
            referral_code,
            reCaptchaToken: loginConfigs.recaptcha?.is_enabled
              ? reCaptcha_token
              : undefined,
          });
          webToastService.showInfo("Check Wallet To Sign!👀");
          const signature = await signMessageAsync({
            message: data?.nonce!,
          });
          web3login({ address, signature, invite_id, referral_code });
        } catch (e: any) {
          disconnect.disconnect();
          if (e?.code === 4001) {
            webToastService.showError("Sign request was rejected!");
            // Dispatch a reject action from here
            dispatch(authWalletAddressRejectSignAction({ address }));
          }
        }
      } else {
        // for the Profile setting add wallet
        try {
          const data = await userService.addWalletAddress({ address });
          const signature = await signMessageAsync({
            message: data?.nonce!,
          });
          dispatch(
            addWalletAddressVerifySignAction({
              req: {
                address,
                signature,
              },
            })
          );
        } catch (e: any) {
          disconnect.disconnect();
          if (e?.code === 4001) {
            webToastService.showError("Sign request was rejected!");
            dispatch(addWalletAddressRejectSignAction());
          }
        }
      }
    },
  });

  return children;
};

const mapStateToProps = (state: AppState) => ({
  userState: userSelector(state.user),
});

const mapDispatchToProps = {
  web3login: authVerifySignatureAction,
  addWalletAddressVerify: addWalletAddressVerifySignAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(WalletAccountHookContainer));
