import { delay } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ToastContainerIds } from '../constants/constants';
import useShowInstallPromptInIOS from '../hooks/fcm/useShowInstallPromptInIOS';
import { AuthState } from '../redux/reducers/auth.reducer';
import { BrandState } from '../redux/reducers/brand.reducer';
import { authSelector, brandConfigSelector, brandSelector } from '../redux/selectors/app.selectors';
import { SHOWN_PWA_TOAST, webStorageService } from '../services/WebStorageService';
import { BrandConfigKeys } from '../utils/enums';

interface PWAInstallPromptProps {
}

const PWAInstallPrompt: React.FC<PWAInstallPromptProps> = (props) => {
    const { brand, configEntities, pwa_data }: BrandState = useSelector(brandSelector);
    const { userID }: AuthState = useSelector(authSelector);
    const [installPromptEvent, setInstallPromptEvent] = useState<any>(null)
    const pwaConfig = brandConfigSelector(configEntities, BrandConfigKeys.PWA_CONFIG);


    const showInstallInfoOnIOS = useCallback(() => {
        toast(
            <div className="flex justify-between mr-3 text-center w-fit">
                <span>
                    Enhance your experience by installing our app to your home screen!
                </span>
            </div>,
            {
                closeOnClick: false,
                className:
                    "relative mx-auto mb-2 flex px-4 !w-fit rounded-xl text-sm !font-secondary justify-between overflow-hidden cursor-pointer bg-primaryModal/90 backdrop-blur-md text-primaryText border border-primaryText/10",
                closeButton: ({ closeToast }) => (
                    <div className="flex flex-col items-center justify-center gap-1 py-1 whitespace-nowrap">
                        <button onClick={() => {
                            window.open('https://support.google.com/chrome/answer/9658361?hl=en&co=GENIE.Platform%3DiOS', '_blank')
                            closeToast()
                        }} className="py-1 text-brandGreen">
                            Know more🤔
                        </button>
                        <button
                            onClick={() => {
                                closeToast();
                            }}
                            className="py-1 whitespace-nowrap text-brandError"
                        >
                            Later🤫
                        </button>
                    </div>
                ),
                autoClose: false,
                hideProgressBar: true,
                position: "bottom-center",
                toastId: ToastContainerIds.APP_INSTALL_PROMPT_TOAST
            }
        );
    }, [])

    useShowInstallPromptInIOS(
        showInstallInfoOnIOS,
        { is_brand_id: !!brand?.id, user_id: userID!, is_pwa_enabled: pwaConfig?.value?.is_enabled },
        pwa_data?.manifest_data
    )

    useEffect(() => {
        const handleBeforeInstallPrompt: EventListenerOrEventListenerObject = async (event: any) => {
            event.preventDefault(); // Prevent the default browser prompt
            setInstallPromptEvent(event)
            await ((event as any).userChoice)
            event = null;
            window.removeEventListener(
                "beforeinstallprompt",
                handleBeforeInstallPrompt
            );
        };
        window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
        // Destroy
        return () => {
            window.removeEventListener(
                "beforeinstallprompt",
                handleBeforeInstallPrompt
            );
        };
    }, []);

    useEffect(() => {
        const isAlreadyShown = webStorageService.getSessionStorageValue(SHOWN_PWA_TOAST) === 'true'
        const timerId = delay(() => {
            if (pwaConfig?.value?.is_enabled && userID !== -1 && brand?.id && installPromptEvent && !isAlreadyShown) {
                webStorageService.setSessionStorageValue(SHOWN_PWA_TOAST, 'true')
                toast(<div className="flex justify-between mr-3 w-fit">
                    <div>App is ready to install</div>
                </div>, {
                    closeOnClick: false,
                    className: "relative mx-auto mb-2 flex px-4 !w-fit rounded-xl text-sm !font-secondary justify-between overflow-hidden cursor-pointer bg-primaryModal/90 backdrop-blur-md text-primaryText border border-primaryText/10",
                    closeButton: ({ closeToast }) => <div className='flex items-center justify-center space-x-2'>
                        <button onClick={async () => {
                            // Check if the user is logged in and the prompt hasn't been shown yet
                            if (installPromptEvent) {
                                closeToast();
                                installPromptEvent?.prompt(); // Show the installation prompt
                                if (await ((installPromptEvent as any).userChoice)) {
                                    setInstallPromptEvent(null)
                                }
                            }
                        }} className="text-brandGreen">Install😃👍</button>
                        <button onClick={() => {
                            closeToast()
                            setInstallPromptEvent(null)
                        }} className="text-brandError">Cancel👎</button>
                    </div>,
                    autoClose: false,
                    hideProgressBar: true,
                    position: 'bottom-center',
                    toastId: ToastContainerIds.APP_INSTALL_PROMPT_TOAST
                })
            }
        }, 20000)
        return () => {
            if (timerId) clearTimeout(timerId);
        }
    }, [brand?.id, installPromptEvent, pwaConfig?.value?.is_enabled, userID])

    return (
        <></>
    );
};

export default React.memo(PWAInstallPrompt);