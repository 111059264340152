import { useEffect } from "react";
import { pushNotificationService } from "../../services/PushNotificationServices";
import { FCM_TOKEN, webStorageService } from "../../services/WebStorageService";

const useSendTokenToServer = (
  token: string,
  device_id: string,
  user_id: number | undefined
) => {
  useEffect(() => {
    if (user_id !== -1) {
      const sendToServer = async () => {
        return await pushNotificationService.sendToServer({
          fcm_token: token,
          device_id,
        });
      };
      if (
        token &&
        device_id &&
        webStorageService.getLocalStorageValue(FCM_TOKEN) !== token
      ) {
        sendToServer()
          .then(() => {
            // After the token is sent to server, set it in local storage
            webStorageService.setLocalStorageValue(FCM_TOKEN, token);
          })
          .catch((e: any) => {
            console.log(e);
          });
      }
    }
  }, [token, device_id, user_id]);
};

export default useSendTokenToServer;
