import { useEffect } from "react";
import { pushNotificationService } from "../../services/PushNotificationServices";
import { FCM_TOKEN, webStorageService } from "../../services/WebStorageService";
import {
  deleteMessagingToken,
  messaging,
} from "../../services/firebase/firebase";
import { webToastService } from "../../services/WebToastService";

const useDeleteStaleFCMToken = () => {
  useEffect(() => {
    const fcm_token_in_local_storage =
      webStorageService.getLocalStorageValue(FCM_TOKEN);

    const deleteFCMTokenFromClient = async () => {
      const messagingObj = messaging();
      webStorageService.removeLocalStorageValue(FCM_TOKEN);
      return await deleteMessagingToken(messagingObj);
    };
    const deleteFCMTokenFromServer = async (fcm_token: string) => {
      await pushNotificationService.deleteFromServer({
        fcm_token,
      });
    };

    webStorageService.getAuthToken().then((auth_token) => {
      const is_logged_in = !!auth_token;
      // If user is not logged in but fcm_token is present, that means the fcm_token is stale
      if (!is_logged_in && fcm_token_in_local_storage) {
        console.log("Deleting Stale FCM Token");
        deleteFCMTokenFromServer(fcm_token_in_local_storage);
        deleteFCMTokenFromClient();
      }
    });
  }, []);
};

export default useDeleteStaleFCMToken;
