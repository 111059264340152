import { delay, isArray, isNull, isUndefined, pickBy } from "lodash";
import moment from "moment";
import { Area as CropAreaType } from "react-easy-crop/types";
import NextRouteConfig from "../../next-routes/NextRouteConfig";
import { myItemsFilters } from "../constants/constants";
import { ENV_FE_BASE_URL } from "../constants/secret";
import { BrandConfig } from "../models/entities/BrandConfig";
import BrandSocialCredential from "../models/entities/BrandSocialsCredentials";
import { Engagement } from "../models/entities/Engagement";
import { User } from "../models/entities/User";
import { PWAManifestFile } from "../models/types/PWAManifestFileType";
import { brandConfigSelector } from "../redux/selectors/app.selectors";
import { COOKIE_CONSENT_VALUES } from "../services/WebStorageService";
import {
  BrandBackgroundColor,
  BrandConfigKeys,
  CardTypes,
  EngagementType,
  ExclusiveContentActions,
  FilterBy,
  InstagramEngagementType,
  ItemStatus,
  Providers,
  QuestFilterBy,
  TikTokEngagementType,
  TwitterEngagementType,
  YoutubeEngagementType,
} from "./enums";

const generalImageFormat = [".svg", ".png", ".jpg", ".jpeg", ".hic", ".heic"];

export class Utils {
  static sanitizeObject = (data: any) => {
    return pickBy(data, (v: any) =>
      isArray(v) ? !!v.length : v !== null && v !== undefined && v !== ""
    );
  };

  static sanitizeArray = (data: Array<any>) => {
    return data.filter((v) => v !== undefined);
  };

  static toHex(stringToConvert: string) {
    return stringToConvert
      .split("")
      .map((c) => c.charCodeAt(0).toString(16).padStart(2, "0"))
      .join("");
  }
  static getUniqueId = () =>
    (
      Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
    ).toUpperCase();

  static getTraitValueBySlug(
    tokenCategory: CardTypes,
    trait: string,
    key?: string
  ) {
    if (tokenCategory === CardTypes.heroes) {
      // @ts-ignore
      return (heroTraits as any)[trait][key] ?? (heroTraits as any)[trait];
    } else {
      // @ts-ignore
      return (godTraits as any)[trait][key] ?? (godTraits as any)[trait];
    }
  }
  static getUserItemFiltersBySlug = (text: string) => {
    let value: string = "";
    myItemsFilters.forEach((filter: any) => {
      if (filter.filterBy === text) {
        value = filter.text;
      }
    });
    return value;
  };
  static getTrimmedString = (text: string, limit: number) => {
    if (text.length > limit) return text.substring(0, limit) + "...";
    return text;
  };

  static ImageFormats(): Array<string> {
    return generalImageFormat;
  }

  static isTruthyValue(value: any): boolean {
    return !isNull(value) && !isUndefined(value) && value !== "";
  }

  static getRadianAngle(degreeValue: number): number {
    return (degreeValue * Math.PI) / 180;
  }

  static createImage(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });
  }

  static hexToRgb(hex: string) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");

      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }

      const r = parseInt(c[0] + c[1], 16);
      const g = parseInt(c[2] + c[3], 16);
      const b = parseInt(c[4] + c[5], 16);

      return `${r}, ${g}, ${b}`;
    }
    throw new Error("Bad Hex");
  }

  static async getCroppedImg(
    imageSrc: string,
    crop: CropAreaType,
    fileName: string,
    rotation = 0,
    maxArea?: number
  ): Promise<string> {
    const image = await this.createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    let safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    if (safeArea > (maxArea || 0)) {
      safeArea = maxArea || 0;
    }

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx?.translate(safeArea / 2, safeArea / 2);
    ctx?.rotate(this.getRadianAngle(rotation));
    ctx?.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx?.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );
    const data = ctx?.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    (canvas as any).width = crop.width;
    (canvas as any).height = crop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx?.putImageData(
      data as any,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - (crop?.x || 0)),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - (crop?.y || 0))
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        (blob as any).name = fileName;
        resolve(URL.createObjectURL(blob));
      }, "image/jpeg");
    });
  }

  public debounce = (fun: () => void, delayInMillis: number) => {
    let timer: NodeJS.Timeout;
    return function () {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => fun(), delayInMillis);
    };
  };

  public throttle = (fun: () => void, delayInMillis: number) => {
    let preventRunning = false;
    return function () {
      if (preventRunning) {
        return;
      }
      fun();
      preventRunning = true;
      setTimeout(() => {
        preventRunning = false;
      }, delayInMillis);
    };
  };

  static getEllipsisedAddress(address: string) {
    return address.slice(0, 8) + "..." + address.slice(address.length - 4);
  }

  static getEllipsisedEmail(email: string, numChars?: number) {
    const [username, domain] = email.split("@");
    // Ellipsize the username
    const ellipsizedUsername =
      username.slice(0, numChars ? numChars : 5) + "...";

    // Construct the ellipsized email address
    const ellipsizedEmail = ellipsizedUsername + "@" + domain;

    return ellipsizedEmail;
  }

  static getEllipsisedText(text: string, range?: number) {
    if (text?.length > (range ?? 10)) {
      return range ? text.slice(0, range) + "..." : text.slice(0, 8) + "...";
    } else {
      return text;
    }
  }

  static extractYoutubeVideoId(url: string) {
    // Define the regular expression pattern for matching YouTube video IDs
    var pattern =
      /(?:youtube\.com\/(?:[^\/\n\s]+\/(?:\S+\/|shorts\/)?|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    // Search for the pattern in the URL
    var match = url.match(pattern);

    // If a match is found, return the video ID group
    if (match) {
      return match[1];
    } else {
      return null;
    }
  }

  static iterateEnum<T>(enumRef: any): T[] {
    return Object.keys(enumRef).map((key) => enumRef[key]);
  }

  static buttonText<T>(isWalletConnected: boolean, item: any): string {
    if (isWalletConnected) {
      if (item.quantity_total === item.quantity_used) {
        return "Sold Out";
      }
      if (item.purchased_quantity === item.per_wallet_quantity) {
        return "Redeemed";
      }
      if (!!item?.live_at && moment(item.live_at).isAfter(moment())) {
        return moment(item.live_at).format(`YY/MM/DD HH:mmA`);
      }
    } else {
      return "Connect";
    }
    return "Redeem";
  }
  static engagementActivity<T>(activity: string): string {
    if (activity.toLocaleLowerCase().includes(Providers.TWITTER)) {
      return Providers.TWITTER;
    } else if (activity.toLocaleLowerCase().includes(Providers.DISCORD)) {
      return Providers.DISCORD;
    } else if (activity.toLocaleLowerCase().includes(Providers.INSTAGRAM)) {
      return Providers.INSTAGRAM;
    } else {
      return `Other`;
    }
  }

  static socialUsername<T>(user: User, provider: Providers) {
    return user?.user_socials?.find((social) => social.provider === provider)
      ?.username;
  }

  static isAccessTokenAvailable<T>(brand: any, provider: Providers): any {
    switch (provider) {
      case Providers.TWITTER: {
        return brand?.twitter?.access_token;
      }
      case Providers.DISCORD: {
        return brand?.discord?.access_token;
      }
      case Providers.INSTAGRAM: {
        return brand?.instagram?.access_token;
      }
    }
  }
  static background<T>(bgColor: any): any {
    switch (bgColor) {
      case BrandBackgroundColor.WHITE: {
        return BrandBackgroundColor.WHITE;
      }
      case BrandBackgroundColor.BLACK: {
        return BrandBackgroundColor.BLACK;
      }
      case null: {
        return "null";
      }
      default: {
        return "";
      }
    }
  }
  static isSociaLCardDisable<T>(bgColor: any): any {
    switch (bgColor) {
      case BrandBackgroundColor.WHITE: {
        return BrandBackgroundColor.WHITE;
      }
      case BrandBackgroundColor.BLACK: {
        return BrandBackgroundColor.BLACK;
      }
      case null: {
        return "null";
      }
      default: {
        return "";
      }
    }
  }

  static socialClientIds<T>(provider: any, contextValue: any): any {
    switch (provider) {
      case Providers.TWITTER: {
        return contextValue.twitter_client_id;
      }
      case Providers.INSTAGRAM: {
        return contextValue.instagram_client_id;
      }
      case Providers.DISCORD: {
        return contextValue.discord_client_id;
      }
      case Providers.YOUTUBE: {
        return contextValue.youtube_client_id;
      }
      case Providers.SPOTIFY: {
        return contextValue.spotify_client_id;
      }
      case Providers.LINKEDIN: {
        return contextValue.linkedin_client_id;
      }
      case Providers.TIKTOK: {
        return contextValue.tiktok_client_id;
      }
      default: {
        return "";
      }
    }
  }
  static getUnlockedSocials<T>(context: any): any {
    let unlockedEngagements: string[] = [];
    const providers = Utils.iterateEnum(Providers);
    providers.forEach((p: any) => {
      if (Utils.socialClientIds(p, context)) {
        unlockedEngagements = [...unlockedEngagements, p];
      }
    });
    return unlockedEngagements;
  }
  static numberWithCommas<T>(x: number): any {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  static capitalizeFirstLetter<T>(s: string): any {
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }

  static milestoneScale<T>(target: number, index: number): any {
    switch (index) {
      case 0:
        return Math.round((target * 1) / 4);
      case 1:
        return Math.round((target * 2) / 4);
      case 2:
        return Math.round((target * 3) / 4);
      case 3:
        return Math.round(target);
    }
  }
  static isContainEveryElement<T>(array: T[], targetArray: T[]): boolean {
    return targetArray.every((e) => array.includes(e));
  }

  static isContainSomeElement<T>(array: T[], targetArray: T[]): boolean {
    return targetArray.some((e) => array.includes(e));
  }

  static socialLoginCredit<T>(
    brandConfig: Record<number, BrandConfig>,
    provider: Providers
  ) {
    switch (provider) {
      case Providers.TWITTER: {
        return brandConfigSelector(brandConfig, BrandConfigKeys.TWITTER_CREDITS)
          ?.value?.login;
      }
      case Providers.DISCORD: {
        return brandConfigSelector(brandConfig, BrandConfigKeys.DISCORD_CREDITS)
          ?.value?.login;
      }
      case Providers.YOUTUBE: {
        return brandConfigSelector(brandConfig, BrandConfigKeys.YOUTUBE_CREDITS)
          ?.value?.login;
      }
      case Providers.SPOTIFY: {
        return brandConfigSelector(brandConfig, BrandConfigKeys.SPOTIFY_CREDITS)
          ?.value?.login;
      }
      case Providers.LINKEDIN: {
        return brandConfigSelector(
          brandConfig,
          BrandConfigKeys.LINKEDIN_CREDITS
        )?.value?.login;
      }
      case Providers.INSTAGRAM: {
        return brandConfigSelector(
          brandConfig,
          BrandConfigKeys.INSTAGRAM_CREDITS
        )?.value?.login;
      }
      case Providers.TIKTOK: {
        return brandConfigSelector(brandConfig, BrandConfigKeys.TIKTOK_CREDITS)
          ?.value?.login;
      }

      default: {
        return "";
      }
    }
  }
  static isProviderEnabled<T>(
    brandConfig: Record<number, BrandConfig>,
    provider: string
  ): boolean {
    switch (provider) {
      case Providers.QUEST: {
        return brandConfigSelector(brandConfig, BrandConfigKeys.QUEST_CONFIG)
          ?.value?.is_enabled;
      }
      case Providers.TWITTER: {
        return brandConfigSelector(brandConfig, BrandConfigKeys.TWITTER_CONFIG)
          ?.value?.is_enabled;
      }
      case Providers.DISCORD: {
        return brandConfigSelector(brandConfig, BrandConfigKeys.DISCORD_CONFIG)
          ?.value?.is_enabled;
      }
      case Providers.YOUTUBE: {
        return brandConfigSelector(brandConfig, BrandConfigKeys.YOUTUBE_CONFIG)
          ?.value?.is_enabled;
      }
      case Providers.INSTAGRAM: {
        return brandConfigSelector(
          brandConfig,
          BrandConfigKeys.INSTAGRAM_CONFIG
        )?.value?.is_enabled;
      }
      case Providers.SPOTIFY: {
        return brandConfigSelector(brandConfig, BrandConfigKeys.SPOTIFY_CONFIG)
          ?.value?.is_enabled;
      }
      case Providers.LINKEDIN: {
        return brandConfigSelector(brandConfig, BrandConfigKeys.LINKEDIN_CONFIG)
          ?.value?.is_enabled;
      }
      case Providers.TIKTOK: {
        return brandConfigSelector(brandConfig, BrandConfigKeys.TIKTOK_CONFIG)
          ?.value?.is_enabled;
      }
      default: {
        return false;
      }
    }
  }
  static getChromeWindowConfigs = (win: Window) => {
    return {
      height: 500,
      width: 600,
      top: (win.screen.height - 500) / 2,
      left: (win.screen.width - 600) / 2,
      location: "no",
      toolbar: "no",
      status: "no",
      directories: "no",
      menubar: "no",
      scrollbars: "no",
      centerscreen: "yes",
      chrome: "yes",
    };
  };

  static handelMyActivityMeta = (engagement: Engagement, provider: string) => {
    switch (provider) {
      case Providers.TWITTER:
        return {
          username: engagement.meta?.user_details?.username
            ? Utils.getEllipsisedText(
                `@${engagement.meta?.user_details?.username}`,
                15
              )
            : "",
          link: engagement.provider_id
            ? engagement.type === TwitterEngagementType.FOLLOW
              ? `https://twitter.com/intent/follow?screen_name=${engagement.meta?.user_details?.username}`
              : `https://twitter.com/user/status/${engagement.provider_id}`
            : "",
        };
      case Providers.YOUTUBE: {
        return {
          username:
            engagement.type === EngagementType.SUBSCRIBE
              ? engagement.meta?.channel_details?.username
              : engagement.meta?.video_details?.username ?? "",
          link: engagement.provider_id
            ? engagement.type === YoutubeEngagementType.SUBSCRIBE
              ? `https://www.youtube.com/${engagement.meta?.channel_details?.username}?sub_confirmation=1`
              : `https://youtu.be/${engagement?.provider_id}`
            : "",
        };
      }
      case Providers.DISCORD: {
        return {
          username: !!engagement.meta
            ? Utils.getEllipsisedText(engagement.meta?.server_details?.name, 15)
            : "",
          link: engagement.meta
            ? `${engagement.meta?.["server_details"]?.invite_url}`
            : "",
        };
      }
      case Providers.TIKTOK:
        return {
          username: engagement?.meta?.user_details?.username
            ? Utils.getEllipsisedText(
                `@${engagement?.meta?.user_details?.username}`,
                15
              )
            : "",
          link: engagement.provider_id
            ? engagement.type === TwitterEngagementType.FOLLOW
              ? `https://www.tiktok.com/@${engagement.meta.user_details.username}/`
              : engagement.meta?.post_details?.post_url
            : "",
        };
      case Providers.INSTAGRAM:
        return {
          username: engagement?.meta?.user_details?.username
            ? Utils.getEllipsisedText(
                `@${engagement?.meta?.user_details?.username}`,
                15
              )
            : "",
          link: engagement.provider_id
            ? engagement.type === InstagramEngagementType.FOLLOW
              ? `https://www.instagram.com/${engagement.meta?.user_details?.username}/`
              : `https://www.instagram.com/p/${engagement.provider_id}`
            : "",
        };
      default: {
        return {
          username: "",
          link: "",
        };
      }
    }
  };

  static getTwitterEngagementsSiblingsPoints = (engagements: Engagement[]) => {
    return {
      like: engagements.find((eng) => eng.type === TwitterEngagementType.LIKE)
        ?.per_wallet,
      retweet: engagements.find(
        (eng) => eng.type === TwitterEngagementType.RETWEET
      )?.per_wallet,
      reply: engagements.find((eng) => eng.type === TwitterEngagementType.REPLY)
        ?.per_wallet,
    };
  };
  static getTikTokEngagementsSiblingsPoints = (engagements: Engagement[]) => {
    return {
      like: engagements.find((eng) => eng.type === TikTokEngagementType.LIKE)
        ?.per_wallet,
      share: engagements.find((eng) => eng.type === TikTokEngagementType.SHARE)
        ?.per_wallet,
      comment: engagements.find(
        (eng) => eng.type === TikTokEngagementType.COMMENT
      )?.per_wallet,
    };
  };

  static getInstagramEngagementsSiblingsPoints = (
    engagements: Engagement[]
  ) => {
    return {
      like: engagements.find((eng) => eng.type === InstagramEngagementType.LIKE)
        ?.per_wallet,
      share: engagements.find(
        (eng) => eng.type === InstagramEngagementType.SHARE
      )?.per_wallet,
      comment: engagements.find(
        (eng) => eng.type === InstagramEngagementType.COMMENT
      )?.per_wallet,
    };
  };

  static handleIsBrandSocialCredentialAvailable = (
    brandSocialCredentials: Record<string, BrandSocialCredential>,
    provider: string,
    isTwitterApiPlanPaid: boolean
  ) => {
    const clientId = brandSocialCredentials?.[provider]?.client_id;
    const accessToken = brandSocialCredentials?.[provider]?.access_token;

    switch (provider) {
      case Providers.TWITTER: {
        return isTwitterApiPlanPaid ? !(clientId && accessToken) : false;
      }
      case Providers.YOUTUBE:
      case Providers.DISCORD: {
        return !(clientId && accessToken);
      }
      case Providers.INSTAGRAM:
      case Providers.SPOTIFY:
      case Providers.TIKTOK:
      case Providers.LINKEDIN: {
        return false;
      }
      default:
        return false;
    }
  };

  static getYoutubeEngagementsSiblingsPoints = (engagements: Engagement[]) => {
    return {
      like: engagements.find((eng) => eng.type === YoutubeEngagementType.LIKE)
        ?.per_wallet,
      comment: engagements.find(
        (eng) => eng.type === YoutubeEngagementType.COMMENT
      )?.per_wallet,
    };
  };

  static formatMillisecond<T>(millisecond: number): string {
    let seconds = Math.floor(millisecond / 1000);
    const hours = Math.floor(seconds / 3600);
    seconds = seconds % 3600;
    const minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    return hours >= 1
      ? `${hours >= 10 ? hours : `0${hours}`} :${
          minutes >= 10 ? minutes : `0${minutes}`
        } :${seconds >= 10 ? seconds : `0${seconds}`}`
      : ` ${minutes >= 10 ? minutes : `0${minutes}`} : ${
          seconds >= 10 ? seconds : `0${seconds}`
        }`;
  }
  static socialsData<T>(
    provider: Providers,
    domain: string,
    client_id?: string
  ): any {
    switch (provider) {
      case Providers.TWITTER: {
        if (!client_id) {
          return undefined;
        }
        return {
          name: "twitter",
          icon: `/`,
          provider: Providers.TWITTER,
          redirect_uri: domain + NextRouteConfig.USER.HOME.socials_token,
          url: `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${client_id}&redirect_uri=${encodeURIComponent(
            ENV_FE_BASE_URL + NextRouteConfig.USER.HOME.socials_token
          )}&scope=tweet.read%20users.read%20follows.read%20like.read%20offline.access&state=${
            Providers.TWITTER
          }&code_challenge=challenge&code_challenge_method=plain`,
        };
      }
      case Providers.DISCORD: {
        if (!client_id) {
          return undefined;
        }
        return {
          name: "discord",
          icon: `/`,
          provider: Providers.DISCORD,
          redirect_uri: domain + NextRouteConfig.USER.HOME.socials_token,
          url: `https://discord.com/api/oauth2/authorize?client_id=${client_id}&redirect_uri=${encodeURIComponent(
            ENV_FE_BASE_URL + NextRouteConfig.USER.HOME.socials_token
          )}&state=${
            Providers.DISCORD
          }&response_type=code&scope=identify%20guilds`,
        };
      }
      case Providers.INSTAGRAM:
        if (!client_id) {
          return undefined;
        }
        return {
          name: "instagram",
          icon: `/`,
          provider: Providers.INSTAGRAM,
          redirect_uri: domain + NextRouteConfig.USER.HOME.socials_token,
          url: `https://api.instagram.com/oauth/authorize?client_id=${client_id}&redirect_uri=${encodeURIComponent(
            ENV_FE_BASE_URL + NextRouteConfig.USER.HOME.socials_token
          )}&state=${
            Providers.INSTAGRAM
          }&scope=user_profile,user_media&response_type=code`,
        };
      case Providers.TIKTOK:
        if (!client_id) {
          return undefined;
        }
        return {
          name: "tiktok",
          icon: `/`,
          provider: Providers.TIKTOK,
          redirect_uri: domain + NextRouteConfig.USER.HOME.socials_token,
          url: `https://www.tiktok.com/v2/auth/authorize?client_key=${client_id}&redirect_uri=${encodeURIComponent(
            ENV_FE_BASE_URL +
              NextRouteConfig.USER.HOME.socials_token +
              `&state=${Providers.TIKTOK}&scope=user.info.basic&response_type=code`
          )}`,
        };
      case Providers.YOUTUBE:
        if (!client_id) {
          return undefined;
        }
        return {
          name: "youtube",
          icon: `/`,
          provider: Providers.YOUTUBE,
          redirect_uri: domain + NextRouteConfig.USER.HOME.socials_token,
          url: `https://accounts.google.com/o/oauth2/auth?scope=https://www.googleapis.com/auth/youtube.force-ssl&response_type=code&access_type=offline&include_granted_scopes=true&redirect_uri=${encodeURIComponent(
            ENV_FE_BASE_URL + NextRouteConfig.USER.HOME.socials_token
          )}&state=${Providers.YOUTUBE}&client_id=${client_id}`,
        };
      case Providers.GMAIL:
        if (!client_id) {
          return undefined;
        }
        return {
          name: "gmail",
          icon: `/`,
          provider: Providers.GMAIL,
          redirect_uri: domain + NextRouteConfig.USER.HOME.socials_token,
          url: `https://accounts.google.com/o/oauth2/auth?scope=https://www.googleapis.com/auth/gmail.readonly&response_type=code&access_type=offline&redirect_uri=${encodeURIComponent(
            ENV_FE_BASE_URL + NextRouteConfig.USER.HOME.socials_token
          )}&state=${Providers.GMAIL}&client_id=${client_id}`,
        };
      case Providers.SPOTIFY:
        if (!client_id) {
          return undefined;
        }
        return {
          name: "spotify",
          icon: `/`,
          provider: Providers.SPOTIFY,
          redirect_uri: domain + NextRouteConfig.USER.HOME.socials_token,
          url: `https://accounts.spotify.com/authorize?client_id=${client_id}&response_type=code&redirect_uri=${encodeURIComponent(
            ENV_FE_BASE_URL + NextRouteConfig.USER.HOME.socials_token
          )}&state=${Providers.SPOTIFY}`,
        };
      case Providers.LINKEDIN:
        if (!client_id) {
          return undefined;
        }
        return {
          name: "linkedin",
          icon: `/`,
          provider: Providers.LINKEDIN,
          redirect_uri: domain + NextRouteConfig.USER.HOME.socials_token,
          url: `https://www.linkedin.com/oauth/v2/authorization?client_id=${client_id}&response_type=code&redirect_uri=${encodeURIComponent(
            ENV_FE_BASE_URL + NextRouteConfig.USER.HOME.socials_token
          )}&state=${Providers.LINKEDIN}&scope=r_liteprofile`,
        };
      default: {
        return {};
      }
    }
  }

  static removeObjectEntry<T>(obj: T | any, x: number) {
    delete obj[x];
    for (let i = x + 1; obj[i] !== undefined; i++) {
      obj[i - 1] = obj[i];
      delete obj[i];
    }
  }
  static hasValue = (values: any[]) => {
    return values.every((value) => !!value?.value);
  };
  static truncateParagraphs(value: string, limit = 14) {
    const words = value.split(" ").length;
    if (words > 15 || value.length > 50) {
      return {
        isRequired: true,
        value:
          value.length > 50
            ? value.slice(0, 50)
            : value
                .split(" ")
                .splice(0, limit + 1)
                .join(" "),
      };
    } else {
      return {
        isRequired: false,
        value,
      };
    }
  }

  static truncateString(value: string, limit = 14) {
    // 7 is ...more
    if (value.length + 7 > limit) {
      return {
        isRequired: true,
        value: value.slice(0, limit),
      };
    } else {
      return {
        isRequired: false,
        value,
      };
    }
  }

  static handelStatusText(
    status: string,
    isEdit: boolean,
    initialStatus?: string
  ) {
    if (isEdit) {
      if (initialStatus === ItemStatus.ARCHIVED) {
        if (status === ItemStatus.PUBLISHED) {
          return "publish";
        } else {
          return "update";
        }
      } else if (initialStatus === ItemStatus.PUBLISHED) {
        if (status === ItemStatus.ARCHIVED) {
          return "archive";
        } else {
          return "update";
        }
      } else {
        // draft case
        if (status === ItemStatus.PUBLISHED) {
          return "publish";
        } else {
          return "update";
        }
      }
    } else {
      if (status === ItemStatus.PUBLISHED) {
        return "publish";
      } else {
        return "draft";
      }
    }
  }

  static getBadges(lifeTimePoints: number, tierData: any) {
    const badges: string[] = [];
    tierData.forEach((e: { targetPoints: number; tag: string }) => {
      if (lifeTimePoints != null && lifeTimePoints >= e.targetPoints) {
        badges.push(e.tag);
      }
    });
    return badges;
  }

  static getVimeoVideoId = (url: string) => {
    return url?.split("/")[4];
  };

  static handleUpdateExclusiveContentRequestObject = (
    values: any,
    initialValues: any
  ) => {
    const unwantedKeys = ["thumbnail_url", "tags_input"];
    const reqObj = Object.keys(values).reduce((accumulator: any, v) => {
      if (!unwantedKeys.includes(v)) {
        //@ts-ignore
        if (values[v] !== initialValues[v]) {
          if (v === "tag_keywords") {
            // @ts-ignore //Here initial value of tags is compared with values in form..
            if (
              (values.tag_keywords as string[]).every((t) =>
                (initialValues.tag_keywords as string[]).includes(t)
              ) &&
              initialValues.tag_keywords?.length === values.tag_keywords?.length
            ) {
              return accumulator;
            } else {
              return {
                ...accumulator,
                meta: {
                  [v]: values[v].length === 0 ? null : values[v]?.join(","),
                },
              };
            }
          }
          if (v === "video_url" && !!values.is_vimeo) {
            return {
              ...accumulator,
              [v]: `https://player.vimeo.com/video/${values[v]}`,
            };
          }
          if (["live_at", "end_at"].includes(v)) {
            return {
              ...accumulator,
              [v]: moment(values[v]).toISOString(),
            };
          }

          return {
            //@ts-ignore
            ...accumulator,
            [v]:
              (values[v] as any) === "" ||
              // (values[v] as any).length === 0 ||
              (values[v] as any) === "<p><br></p>"
                ? null
                : (values[v] as any),
          };
        } else {
          return accumulator;
        }
      } else {
        return accumulator;
      }
    }, {});
    const requiredKeys = Object.keys(reqObj);
    let sanitizedReq: any = {};
    if (requiredKeys.includes("is_media") && !reqObj["is_media"]) {
      sanitizedReq = {
        ...reqObj,
        is_video_uploaded: false,
        is_image_uploaded: false,
        image_url: null,
        video_url: null,
      };
    } else {
      if (requiredKeys.includes("image_object_url")) {
        if (reqObj["image_object_url"]) {
          sanitizedReq = {
            ...reqObj,
            ...sanitizedReq,
            is_image_uploaded: true,
          };
        } else {
          if (reqObj["video_url"] || reqObj["video_object_url"]) {
            sanitizedReq = {
              ...reqObj,
              ...sanitizedReq,
              is_image_uploaded: false,
            };
          }
        }
      }
      if (requiredKeys.includes("image_url")) {
        if (reqObj["image_url"]) {
          sanitizedReq = {
            ...reqObj,
            ...sanitizedReq,
            is_image_uploaded: false,
          };
        } else {
          if (reqObj["video_url"] || reqObj["video_object_url"]) {
            sanitizedReq = {
              ...reqObj,
              ...sanitizedReq,
              is_image_uploaded: false,
            };
          } else {
            sanitizedReq = {
              ...reqObj,
              ...sanitizedReq,
              is_image_uploaded: true,
            };
          }
        }
      }
      if (requiredKeys.includes("video_object_url")) {
        if (reqObj["video_object_url"]) {
          sanitizedReq = {
            ...reqObj,
            ...sanitizedReq,
            is_video_uploaded: true,
          };
        } else {
          if (reqObj["image_url"] || reqObj["image_object_url"]) {
            sanitizedReq = {
              ...reqObj,
              ...sanitizedReq,
              is_video_uploaded: false,
            };
          }
        }
      }
      if (requiredKeys.includes("video_url")) {
        if (reqObj["video_url"]) {
          sanitizedReq = {
            ...reqObj,
            ...sanitizedReq,
            is_video_uploaded: false,
          };
        } else {
          if (reqObj["image_url"] || reqObj["image_object_url"]) {
            sanitizedReq = {
              ...reqObj,
              ...sanitizedReq,
              is_video_uploaded: false,
            };
          } else {
            sanitizedReq = {
              ...reqObj,
              ...sanitizedReq,
              is_video_uploaded: true,
            };
          }
        }
      }
    }

    sanitizedReq = { ...sanitizedReq, ...reqObj };

    delete sanitizedReq?.image_object_url;
    delete sanitizedReq?.video_object_url;
    delete sanitizedReq?.is_media;
    delete sanitizedReq?.is_schedule;
    delete sanitizedReq?.is_vimeo;
    return sanitizedReq;
  };

  static handleExclusiveContentAction(status: string) {
    const actions: string[] = this.iterateEnum(ExclusiveContentActions);
    if (status === ItemStatus.ARCHIVED) {
      return actions.filter((a) => a !== ExclusiveContentActions.ARCHIVE);
    } else if (status === ItemStatus.PUBLISHED) {
      return actions.filter((a) => a !== ExclusiveContentActions.PUBLISH);
    } else if (status === ItemStatus.DRAFT) {
      return actions.filter((a) => a !== ExclusiveContentActions.ARCHIVE);
    } else {
      return actions;
    }
  }

  static handleEntityStatus(config: {
    live_at: string | null;
    end_at: string | null;
    status: string;
    used?: number;
    total?: number | null;
  }) {
    const { end_at, live_at, status, total, used } = config;
    if (status === QuestFilterBy.draft) {
      return {
        status: QuestFilterBy.draft,
        label: "Draft",
      };
    } else if (status === QuestFilterBy.archived) {
      return {
        status: QuestFilterBy.archived,
        label: "Archived",
      };
    } else {
      // When status is published
      if (!!end_at && moment(end_at).isBefore()) {
        return {
          status: QuestFilterBy.past,
          label: "Past",
        };
      }
      if (total && total === used) {
        return {
          status: QuestFilterBy.exhausted,
          label: "Exhausted",
        };
      } else if (!!live_at && moment(live_at).isAfter()) {
        return {
          status: QuestFilterBy.coming_soon,
          label: "Coming soon",
        };
      } else
        return {
          status: QuestFilterBy.live,
          label: "Live",
        };
    }
  }
  static handleRewardStatus(config: {
    live_at: string | null;
    status: string;
    used: number;
    total: number | null;
  }) {
    const { live_at, status, total, used } = config;
    if (status === FilterBy.DRAFT) {
      return {
        status: FilterBy.DRAFT,
        label: "Draft",
      };
    } else if (status === FilterBy.archived) {
      return {
        status: FilterBy.archived,
        label: "Archived",
      };
    } else {
      // When status is published
      if (total && total === used) {
        return {
          status: FilterBy.sold_out,
          label: "Sold Out",
        };
      } else if (!!live_at && moment(live_at).isAfter()) {
        return {
          status: FilterBy.coming_soon,
          label: "Coming soon",
        };
      } else
        return {
          status: FilterBy.live,
          label: "Live",
        };
    }
  }

  static findSecondDuplicateIndex(arr: any[]) {
    // Create an array to store the unique values
    const indexMap: string[] = [];

    for (let i = 0; i < arr.length; i++) {
      const currentValue = arr[i];

      // Check if the value is already in the indexMap
      if (indexMap.includes(currentValue)) {
        // If yes, return the index of the second occurrence
        return i;
      }

      // If not, store the index of the first occurrence
      indexMap.push(currentValue);
    }

    // If no duplicates are found, return -1
    return -1;
  }

  static handlePopoverCardHeight(e: any, id: string, tableId: string) {
    const dimensions = e.currentTarget.getBoundingClientRect();

    const bottom = dimensions.bottom;

    const tableBottom =
      document.getElementById(tableId!)?.getBoundingClientRect()?.bottom || 0;

    delay(() => {
      const menuElement = document.getElementById(id!);

      const menuHeight = menuElement?.clientHeight || 0;

      if (menuElement && bottom && menuHeight && tableBottom) {
        (menuElement as HTMLDivElement).style.transform = "translateX(30px)";
        const shouldRenderCardOnTop = bottom + menuHeight > tableBottom;
        if (shouldRenderCardOnTop) {
          (
            menuElement as HTMLDivElement
          ).style.transform = `translateX(30px) translateY(-${
            10 + bottom + menuHeight - tableBottom
          }px)`;
        }
        if (menuElement?.classList?.contains("!opacity-0")) {
          menuElement?.classList?.toggle("!opacity-0");
        }
      }
    }, 50);
  }

  public static GAEvent<T = any>(eventName: string, payload?: T) {
    // GA suports max 40 chars
    (window as any)?.gtag?.("event", `${eventName}`, payload);
  }

  public static GAConsentUpdate(value: COOKIE_CONSENT_VALUES) {
    (window as any)?.gtag?.("consent", "update", {
      ad_storage: value,
      ad_user_data: value,
      ad_personalization: value,
      analytics_storage: value,
    });
  }

  public static isPWAManifestFileCorrectForIOS(pwaData: PWAManifestFile) {
    return (
      pwaData.name &&
      pwaData.short_name &&
      pwaData.display === "standalone" &&
      pwaData.start_url === `${ENV_FE_BASE_URL}/` &&
      pwaData.scope === `${ENV_FE_BASE_URL}/` &&
      !!pwaData.icons?.length &&
      pwaData.theme_color &&
      pwaData.background_color
    );
  }

  public static isIOSDevice(navigator: Navigator): boolean {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      /iPhone|iPad|iPod/i.test(navigator.userAgent) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }
}

export default Utils;
