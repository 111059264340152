import classNames from "classnames";
import { Form, Formik } from "formik";
import { delay } from "lodash";
import { PencilSimple, X } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import UploadImageIcon from "../../../../../public/icons/upload-image-icon";
import withSuggestionBoxQuest from "../../../../hoc/QuestSuggestionBoxWrapper";
import { ButtonClickPayloadGA } from "../../../../models/types/GAPayload.types";
import {
  userSuggestionSubmissionAction,
  userSuggestionSubmissionErrorResetAction,
} from "../../../../redux/actions/user.actions";
import { AppState } from "../../../../redux/reducers";
import { UserState } from "../../../../redux/reducers/user.reducer";
import { userSelector } from "../../../../redux/selectors/app.selectors";
import Utils from "../../../../utils/Utils";
import { GAEventName, ModalType } from "../../../../utils/enums";
import BrandTooltip from "../../BrandTooltip";
import BrandButton from "../../custom-components/BrandButton";
import CustomCheckbox from "../../custom-components/CustomCheckBox/CustomCheckbox";
import FilePicker from "../../custom-components/file-picker/FilePicker";
import BrandHeading from "../../custom-components/heading/BrandHeading";
import FormikInput from "../../input/Input/FormikInput";
import BrandTextArea from "../../input/textarea/BrandTextArea";
import ConfirmDialog from "../ConfirmDialog";

interface SupportModalFormSectionProps {
  asAQuest?: boolean;
  setIsShareModalOpen?: Function;
  setModalType?: Function;
  isModalTypeSuggestion: boolean;
  modalType: string;
  isShareModalOpen: boolean;
  loading?: boolean;
}

const SupportModalFormSection: React.FC<SupportModalFormSectionProps> = ({
  asAQuest,
  isModalTypeSuggestion,
  modalType,
  isShareModalOpen,
  loading,
}) => {
  const dispatch = useDispatch();

  const [suggestionImageUrl, setSuggestionImageUrl] = useState("");
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [suggestionImage, setSuggestionImage] = useState<File | null>(null);

  const {
    userData,
    is_suggestion_sending,
    safety_check_link_error,
  }: UserState = useSelector((state: AppState) => userSelector(state));

  const [isEmailEdit, setIsEmailEdit] = useState(false);

  useEffect(() => {
    delay(() => {
      setSuggestionImageUrl("");
      setSuggestionImage(null);
    }, 250);
  }, [isShareModalOpen]);

  const onChannelChange = (e: any) => {
    setSuggestionImage(e.target.files[0]);
    setSuggestionImageUrl(URL.createObjectURL(e.target.files[0]));
  };
  const handleValidationSchema = (type: string) => {
    const commonSchema = {
      subject: yup
        .string()
        .max(100, "Maximum 100 character are allowed.")
        .optional(),
      suggestion: yup
        .string()
        .max(1000, "Maximum 1000 character are allowed.")
        .required("Suggestion is required field"),
      email: yup.string().required("Email is required field"),
      disclaimer: yup.bool().oneOf([true], "Field must be checked"),
    };
    return type === ModalType.SUGGESTIONS
      ? {
          ...commonSchema,
          email: yup.string().optional(),
          suggestion: yup
            .string()
            .max(500, "Maximum 500 character are allowed.")
            .required("Suggestion is required field"),
        }
      : commonSchema;
  };
  return (
    <div className={` px-2 md:px-6`}>
      <div className="relative">
        <div className={classNames("sm:pt-10 pt-6 pb-2 md:pb-4 w-full")}>
          <BrandHeading>
            {isModalTypeSuggestion ? "Add to the Suggestion Box" : "Support"}
          </BrandHeading>
        </div>
        <div>
          <Formik
            enableReinitialize
            initialValues={{
              subject: "",
              suggestion: "",
              email: userData?.email || "",
              disclaimer: false,
            }}
            validationSchema={yup
              .object()
              .shape(handleValidationSchema(modalType))}
            onSubmit={(values, { resetForm }) => {
              if (!asAQuest) {
                dispatch(
                  userSuggestionSubmissionAction({
                    image_file_object_url: suggestionImageUrl,
                    type: modalType,
                    req: {
                      suggestion: values.suggestion,
                      email: values.email,
                      subject: values.subject,
                    },
                  })
                );
              }
            }}
          >
            {(formikProps) => {
              return (
                <Form className="space-y-4">
                  <ConfirmDialog
                    showModal={isConfirmationModalOpen}
                    setShowModal={setIsConfirmationModalOpen}
                    confirmButtonText={"Submit"}
                    confirmSubmitting={is_suggestion_sending}
                    confirmButtonOnClick={formikProps.handleSubmit}
                    modalHeaderText={"Are you sure you want to submit."}
                  />
                  <div className="space-y-6">
                    <div className="w-full sm:!w-3/5">
                      <div>
                        <FormikInput
                          name={"subject"}
                          label={
                            isModalTypeSuggestion
                              ? "Suggestion Title"
                              : "Subject"
                          }
                          helperText={`${
                            !formikProps.values.subject
                              ? "Max 100 Characters"
                              : `Available: ${
                                  100 - formikProps.values.subject.length
                                }`
                          }`}
                          placeholder={
                            isModalTypeSuggestion
                              ? "Please enter the title"
                              : "Please enter the subject"
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <BrandTextArea
                        rows={8}
                        helperText={
                          isModalTypeSuggestion
                            ? `${
                                !formikProps.values.suggestion
                                  ? "Max 500 Characters"
                                  : `Available: ${
                                      500 - formikProps.values.suggestion.length
                                    }`
                              }`
                            : `${
                                !formikProps.values.suggestion
                                  ? "Max 1000 Characters"
                                  : `Available: ${
                                      1000 -
                                      formikProps.values.suggestion.length
                                    }`
                              }`
                        }
                        error={
                          safety_check_link_error?.in_suggestion
                            ? `${
                                modalType === ModalType.SUGGESTIONS
                                  ? "Suggestion"
                                  : "Support"
                              } contains malicious link`
                            : formikProps.touched["suggestion"]
                            ? formikProps.errors["suggestion"]
                            : ""
                        }
                        className="w-full"
                        placeholder={
                          isModalTypeSuggestion
                            ? "Please enter your suggestion or feature request"
                            : "Please enter a description of you issue. Please include any relevant info like device, browser, or wallet app."
                        }
                        name={"suggestion"}
                        label={
                          isModalTypeSuggestion ? "Suggestion*" : "Message*"
                        }
                        onkeydown={() => {
                          if (safety_check_link_error?.in_suggestion) {
                            dispatch(
                              userSuggestionSubmissionErrorResetAction({
                                reset_suggestion_safety_link_error: true,
                              })
                            );
                          }
                        }}
                      />
                    </div>
                    <div className="flex flex-col space-y-5 md:flex-row md:space-y-0 md:space-x-5 md:items-end">
                      <div
                        className={classNames(
                          "w-full flex items-center space-x-4"
                        )}
                      >
                        <div className="sm:w-3/5">
                          <FormikInput
                            name={"email"}
                            disabled={!!userData?.email && !isEmailEdit}
                            label={"Contact Email*"}
                            placeholder={"Please enter the email"}
                          />
                        </div>
                        {userData?.email && !isEmailEdit ? (
                          <BrandTooltip
                            id={"support_email_override"}
                            place="top"
                            tooltip={
                              <div className=" w-[200px]  text-[10px] sm:w-full sm:text-sm">
                                You can edit your contact email for this
                                specific ticket if needed.
                              </div>
                            }
                          >
                            <PencilSimple
                              onClick={() => {
                                setIsEmailEdit(true);
                              }}
                              className={classNames(
                                "w-6 h-full mt-4 cursor-pointer"
                              )}
                            />
                          </BrandTooltip>
                        ) : (
                          <BrandButton
                            theme="secondary"
                            onClick={() => {
                              setIsEmailEdit(false);

                              // Reset the email field to its initial value
                              formikProps.setFieldValue(
                                "email",
                                formikProps.initialValues.email
                              );
                            }}
                            className="mt-4 !border !rounded-full !p-1 !scale-100"
                          >
                            <X weight="bold" />
                          </BrandButton>
                        )}
                      </div>
                      {/* {modalType !== ModalType.SUGGESTIONS &&
                    !!brandDiscordUrl ? (
                      <div className="sm:!mb-2 flex sm:block items-center space-x-1">
                        <span className="opacity-50 text-xs sm:!text-sm">
                          <h1>Prefer to create a ticket in Discord?</h1>
                          <a
                            className="underline cursor-pointer"
                            href={brandDiscordUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            JOIN HERE
                          </a>
                        </span>
                      </div>
                    ) : null} */}
                    </div>
                    {isModalTypeSuggestion && !asAQuest ? null : (
                      <div className="flex flex-col space-y-5 sm:flex-row sm:space-y-0 sm:space-x-5 sm:items-center">
                        <div className="w-fit">
                          <FilePicker
                            label="Upload Image"
                            id={"support_image"}
                            url={suggestionImageUrl}
                            height={200}
                            width={200}
                            className="!aspect-square !object-center max-w-[200px]"
                            imageClassName="!aspect-square !object-center max-w-[200px] "
                            onChannelChange={(e) => {
                              onChannelChange(e);
                            }}
                            error={
                              suggestionImage
                                ? suggestionImage?.size > 1000000
                                  ? `Size should be less then 1000 KB`
                                  : ""
                                : ""
                            }
                            buttonElement={
                              <>
                                <div
                                  className={classNames(
                                    "flex rounded-2xl border-2 items-center justify-center min-h-[200px] min-w-[200px] bg-primaryCard/80 border-dashed border-primaryText/40"
                                  )}
                                >
                                  <div className="flex flex-col items-center p-2 text-xs font-semibold text-primaryText/50">
                                    <UploadImageIcon className="w-8 h-8 mb-2 " />
                                    <p>{`Upload Your Image `}</p>
                                    <p>{"(Optional)"}</p>
                                  </div>
                                </div>
                              </>
                            }
                          />
                        </div>
                        <div className="space-y-2">
                          <h1 className="opacity-50 text-xs sm:!text-sm">
                            Tip: Right Click and Inspect. Open your console and
                            take a screenshot. This can help a ton!
                          </h1>
                          <h1 className="opacity-50 text-xs sm:!text-sm">
                            Tip: Max allowed size is 1000 KB.
                          </h1>
                        </div>
                      </div>
                    )}
                  </div>
                  <span className="flex flex-col items-start justify-start pt-4 space-y-2 w-fit">
                    <h1 className="ml-1 text-xs text-justify text-primaryText/50">
                      Upon submitting this{" "}
                      {isModalTypeSuggestion ? "suggestion" : "support"} form,
                      your input will be sent to our support email. By providing
                      your email address, you agree to be contacted via this
                      email for further communication and updates related to
                      your
                      {isModalTypeSuggestion ? " suggestion" : " query"}.
                      Additionally, a confirmation copy will be forwarded to the
                      provided email address.
                    </h1>
                    <span className="flex w-fit">
                      <CustomCheckbox
                        name={"disclaimer"}
                        onChange={(e) => {
                          formikProps?.setFieldValue(
                            "disclaimer",
                            e.target.checked
                          );
                        }}
                        value={formikProps?.values?.disclaimer}
                      >
                        <h1 className="ml-1 text-xs text-primaryText/50">
                          I agree
                        </h1>
                      </CustomCheckbox>
                    </span>
                  </span>
                  <div
                    className={classNames(
                      "w-full flex-col sm:flex-row sm:space-y-0 sm:space-x-6 space-x-0 sm:items-center flex items-start justify-start space-y-2 pb-6 sm:pb-10 md:pb-16"
                    )}
                  >
                    <BrandButton
                      disabled={
                        !formikProps.dirty ||
                        !formikProps.isValid ||
                        loading ||
                        (!!suggestionImage
                          ? suggestionImage?.size > 1000000
                          : false)
                      }
                      isSubmitting={loading}
                      onClick={() => {
                        setIsConfirmationModalOpen(true);
                        Utils.GAEvent<ButtonClickPayloadGA>(
                          GAEventName.BUTTON_CLICK,
                          {
                            event_category: "Button",
                            event_label:
                              modalType === ModalType.SUGGESTIONS
                                ? "Submit Suggestion"
                                : "Submit Support",
                          }
                        );
                      }}
                      type="button"
                    >
                      Submit
                    </BrandButton>
                    {asAQuest ? (
                      <span className="flex items-center mt-5 text-xs opacity-50">
                        <span> {"By submitting you agree to our"}</span>
                        {""}
                        <a href="http://" className="ml-1 underline">
                          {"Terms & Conditions"}
                        </a>
                      </span>
                    ) : null}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SupportModalFormSection;

export const UserQuestSuggestionBoxView = withSuggestionBoxQuest(
  SupportModalFormSection,
  {
    asAQuest: true,
    isModalTypeSuggestion: true,
    modalType: ModalType.SUGGESTIONS,
    isShareModalOpen: false,
  }
);
