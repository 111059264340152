import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  AlternativeLoginPayloadGA,
  ConnectSocialPayloadGA,
  DisconnectSocialPayloadGA,
  UserProfileUpdatePayloadGA,
} from "../../models/types/GAPayload.types";
import { SAGA_PAYLOAD_TYPE } from "../../models/types/SagaPayloadType";
import { webStorageService } from "../../services/WebStorageService";
import { webToastService } from "../../services/WebToastService";
import { baseApiService } from "../../services/api-services/BaseApiService";
import { userService } from "../../services/api-services/UserService";
import Utils from "../../utils/Utils";
import { APIErrorCode, GAEventName, ModalType } from "../../utils/enums";
import {
  ADD_ALTERNATE_LOGIN_VERIFY_EMAIL,
  ADD_EMAIL,
  ADD_EMAIL_CHECK_VERIFICATION,
  ADD_WALLET_ADDRESS,
  ADD_WALLET_ADDRESS_REJECT_SIGN,
  ADD_WALLET_ADDRESS_VERIFY_SIGN,
  GET_USER_REFERRAL_CODE,
  MEMBERS_STATS_DOWNLOAD,
  SOCIAL_LOGIN,
  USERS_LIST_GET,
  USER_ALTERNATE_EMAIL_RESEND,
  USER_AWARD_POINTS,
  USER_DEAUTHORIZE,
  USER_FETCH_ME,
  USER_GET,
  USER_LEADERBOARD_LIST_GET,
  USER_PROFILE_IMAGE_UPDATE,
  USER_PROFILE_UPDATE,
  USER_REVOKE_POINTS,
  USER_SUGGESTION_SUBMISSION,
  USER_UPDATE,
} from "../actions/actions.constants";
import { showMarketingModalAction } from "../actions/auth.actions";
import {
  addAlternateLoginCompletedAction,
  addAlternateLoginEmailVerifyActionError,
  addAlternateLoginEmailVerifyCompletedAction,
  addEmailCheckVerificationErrorAction,
  addEmailCompletedAction,
  addEmailErrorAction,
  addWalletAddressCompletedAction,
  addWalletAddressErrorAction,
  addWalletAddressRejectSignActionCompleted,
  addWalletAddressRejectSignActionError,
  addWalletAddressVerifySignErrorAction,
  getUserReferralCodeCompletedAction,
  getUserReferralCodeErrorAction,
  membersStatsDownloadCompletedAction,
  membersStatsDownloadErrorAction,
  userAlternateLoginEmailResendCompletedAction,
  userAlternateLoginEmailResendErrorAction,
  userAwardPointsCompletedAction,
  userAwardPointsErrorAction,
  userDeauthorizeCompletedAction,
  userDeauthorizeErrorAction,
  userFetchMeAction,
  userFetchMeCompletedAction,
  userFetchMeErrorAction,
  userGetCompletedAction,
  userGetErrorAction,
  userProfileImageUpdateCompletedAction,
  userProfileImageUpdateErrorAction,
  userProfileUpdateCompletedAction,
  userProfileUpdateErrorAction,
  userRevokePointsCompletedAction,
  userSuggestionSubmissionCompletedAction,
  userSuggestionSubmissionErrorAction,
  userUpdateCompletedAction,
  userUpdateErrorAction,
  usersLeaderBoardListGetCompletedAction,
  usersLeaderBoardListGetErrorAction,
  usersListGetCompletedAction,
  usersListGetErrorAction,
} from "../actions/user.actions";

// const brandValue = React.useContext(BrandContext);

function* fetchMeSaga(): any {
  try {
    const response = yield call(userService.me);
    yield put(
      userFetchMeCompletedAction({
        data: {
          user: response.hasOwnProperty("user") ? response.user : null,
          admin: response.hasOwnProperty("admin") ? response.admin : null,
        },
        brand: response.brand,
      })
    );
  } catch (e: any) {
    webStorageService.reset();
    yield put(
      userFetchMeErrorAction((e?.errors && e.errors[0]?.message) || e?.message)
    );
  }
}

function* socialLoginSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    yield call(userService.login, payload);
    Utils.GAEvent<ConnectSocialPayloadGA>(GAEventName.SOCIAL_CONNECT, {
      event_category: "User",
      event_label: `Social Provider Connected - ${payload.provider}`,
    });
    window.close();
    yield put(userFetchMeAction());
  } catch (e: any) {
    webToastService.showError(
      (e?.response.data.message as string).includes(":")
        ? (e?.response.data.message as string).split(";")[1]
        : (e?.response.data.message as string) ??
            ((e?.errors && e.errors[0]?.message) || e?.message)
    );
  }
}

function* profileImageUpdateSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(userService.updateProfileImage);
    yield call(
      baseApiService.uploadFileToS3UsingObjectURL,
      payload.req.profile_image_object_url,
      response.signed_url
    );
    webToastService.showSuccess("Profile image is updated successfully");
    Utils.GAEvent<UserProfileUpdatePayloadGA>(GAEventName.PROFILE_UPDATE_USER, {
      event_category: "User",
      event_label: `Profile Picture`,
    });
    yield put(
      userProfileImageUpdateCompletedAction({ res: { user: response.user } })
    );
  } catch (e: any) {
    yield put(
      userProfileImageUpdateErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}
function* profileUpdateSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(userService.updateProfile, payload.req);
    yield put(
      userProfileUpdateCompletedAction({ res: { user: response.user } })
    );
    yield put(showMarketingModalAction({ res: { open: false } }));
    if (payload.req.hasOwnProperty("username")) {
      Utils.GAEvent<UserProfileUpdatePayloadGA>(
        GAEventName.PROFILE_UPDATE_USER,
        {
          event_category: "User",
          event_label: `Username`,
        }
      );
    }
    webToastService.showSuccess("Profile updated successfully!");
  } catch (e: any) {
    yield put(
      userProfileUpdateErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}
function* userDeauthorizeSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(userService.deauthorize, payload.req);
    yield put(userDeauthorizeCompletedAction({ res: { user: response.user } }));
    if (response.success) {
      webToastService.showSuccess("Successfully Disconnected!");
      Utils.GAEvent<DisconnectSocialPayloadGA>(GAEventName.SOCIAL_DISCONNECT, {
        event_category: "User",
        event_label: `Social Provider Disconnected - ${payload.req?.provider}`,
      });
    }
  } catch (e: any) {
    yield put(
      userDeauthorizeErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* usersListSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(userService.list, {
      search: payload?.req?.search,
      page: payload?.req?.page,
      filters: payload?.req?.filters,
      sort_by: payload?.req?.sort_by,
    });
    yield put(
      usersListGetCompletedAction({
        res: { users: response.users, meta: response.meta },
      })
    );
  } catch (e: any) {
    yield put(
      usersListGetErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}
function* userAwardPointsSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(userService.award, {
      id: payload.req.id,
      points: payload.req.points,
      comments: payload.req.comments,
    });
    yield put(
      userAwardPointsCompletedAction({
        res: { user: { ...response.user } },
        gifted: payload.req.points,
      })
    );
    webToastService.showSuccess(`Awarded points successfully!`);
  } catch (e: any) {
    yield put(
      userAwardPointsErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* userRevokePointsSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(userService.revoke, {
      id: payload.req.id,
      points: payload.req.points,
      comments: payload.req.comments,
    });
    yield put(
      userRevokePointsCompletedAction({
        res: { user: { ...response.user } },
        revoked: payload.req.points,
      })
    );
    webToastService.showSuccess(`Revoked points successfully!`);
  } catch (e: any) {
    yield put(
      userAwardPointsErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}
function* userGetSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(userService.get, payload.req.id);
    yield put(userGetCompletedAction({ res: { user: response.user } }));
  } catch (e: any) {
    yield put(
      userGetErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* userLeaderboardSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(userService.leaderboard);
    yield put(
      usersLeaderBoardListGetCompletedAction({
        res: {
          rank: response.rank,
          users: response.users,
        },
      })
    );
  } catch (e: any) {
    yield put(
      usersLeaderBoardListGetErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* userUpdateSaga(): any {
  try {
    const response = yield call(userService.updateTwitter);
    yield put(
      userUpdateCompletedAction({
        res: {
          user: response.user,
        },
      })
    );
  } catch (e: any) {
    yield put(
      userUpdateErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* addEmail(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(userService.addEmail, data.payload.req);
    yield put(
      addEmailCompletedAction({
        res: {
          code: response.code,
          alternate_email_data: response.alternate_email_data,
        },
      })
    );
  } catch (e: any) {
    yield put(addEmailErrorAction(e.response.data?.message ?? e?.message));
  }
}

function* resendAlternateEmail(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(userService.resendAlternateEmail, data.payload);

    yield put(
      userAlternateLoginEmailResendCompletedAction({
        res: {
          isEmailResent: response.success,
        },
      })
    );
  } catch (e: any) {
    yield put(
      userAlternateLoginEmailResendErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message
      )
    );
  }
}

function* addEmailCheckVerification(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(
      userService.addEmailCheckVerification,
      data.payload.req
    );
    if (!!response.user) {
      yield put(
        addAlternateLoginCompletedAction({
          res: {
            user: response.user ?? null,
          },
        })
      );
      Utils.GAEvent<AlternativeLoginPayloadGA>(GAEventName.ALTERNATIVE_EMAIL, {
        event_category: "User",
        event_label: `Added Alternative Email`,
        method: "email",
      });
      webToastService.showSuccess("Email added Successfully!!");
    }
  } catch (e: any) {
    yield put(
      addEmailCheckVerificationErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message
      )
    );
  }
}

function* alternateLoginVerifyEmail(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(userService.addEmailVerify, data.payload);
    if (response.success) {
      yield put(
        addAlternateLoginEmailVerifyCompletedAction({
          success: response.success,
        })
      );
    }
  } catch (e: any) {
    yield put(
      addAlternateLoginEmailVerifyActionError({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* addWalletAddress(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(userService.addWalletAddress, {
      address: data.payload.req?.wallet_address,
    });
    yield put(
      addWalletAddressCompletedAction({
        res: {
          nonce: response.nonce,
        },
      })
    );
  } catch (e: any) {
    yield put(addWalletAddressErrorAction(e?.message));
  }
}

function* rejectWalletAddressSaga(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(userService.rejectWalletAddressSignature);
    if (response.success) {
      yield put(
        addWalletAddressRejectSignActionCompleted({
          res: {
            success: response.success,
          },
        })
      );
    }
  } catch (e: any) {
    yield put(addWalletAddressRejectSignActionError(e.message.error));
    console.log(e.message.error);
  }
}
function* verifyWalletAddressSaga(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(
      userService.verifyWalletAddressSignature,
      data.payload.req
    );
    if (response.user) {
      yield put(
        addAlternateLoginCompletedAction({
          res: {
            user: response.user,
          },
        })
      );
      Utils.GAEvent<AlternativeLoginPayloadGA>(GAEventName.ALTERNATIVE_EMAIL, {
        event_category: "User",
        event_label: `Added Alternative Wallet`,
        method: "wallet",
      });
      webToastService.showSuccess("Wallet Address added successfully!");
    }
  } catch (e: any) {
    yield put(
      addWalletAddressVerifySignErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message
      )
    );
  }
}

function* userSuggestionSubmissionSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    let success: boolean;
    if (!!payload.image_file_object_url) {
      const imageResponse = yield call(userService.getSuggestionImageSignedUrl);
      yield call(
        baseApiService.uploadFileToS3UsingObjectURL,
        payload.image_file_object_url,
        imageResponse.signed_url
      );
      const response = yield call(userService.suggestionSubmission, {
        ...data.payload?.req,
        namespace: imageResponse.namespace,
        type: payload.type,
      });
      success = response.success;
      yield put(userSuggestionSubmissionCompletedAction({ res: { success } }));
    } else {
      const response = yield call(userService.suggestionSubmission, {
        ...data.payload?.req,
        type: payload.type,
      });
      success = response.success;
      yield put(userSuggestionSubmissionCompletedAction({ res: { success } }));
    }
    if (success) {
      webToastService.showSuccess(
        payload.type === ModalType.SUGGESTIONS
          ? "Suggestion submitted successfully!!"
          : "Submitted successfully!!"
      );
    }
  } catch (e: any) {
    yield put(
      userSuggestionSubmissionErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
        safety_check_link_error:
          e?.response?.data?.code === APIErrorCode.SAFETY_CHECK_LINK_FAILED
            ? {
                in_suggestion: true,
              }
            : undefined,
      })
    );
  }
}

function* getUserReferralCode(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(userService.getUserReferralCode);
    yield put(
      getUserReferralCodeCompletedAction({
        res: response,
      })
    );
  } catch (e: any) {
    yield put(
      getUserReferralCodeErrorAction({
        message: (e?.error && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* downloadMembersStatsSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(userService.downloadMembersStats, {
      search: payload?.req?.search,
      filters: payload?.req?.filters,
    });
    window.open(response.url, "_blank", "noopener noreferrer");
    yield put(membersStatsDownloadCompletedAction());
    webToastService.showSuccess("File Downloaded Successfully!");
  } catch (e: any) {
    yield put(membersStatsDownloadErrorAction(e?.message));
  }
}

function* authSaga() {
  yield all([
    takeLatest(USER_FETCH_ME, fetchMeSaga),
    takeLatest(SOCIAL_LOGIN, socialLoginSaga),
    takeLatest(USER_PROFILE_IMAGE_UPDATE, profileImageUpdateSaga),
    takeLatest(USER_PROFILE_UPDATE, profileUpdateSaga),
    takeLatest(USER_DEAUTHORIZE, userDeauthorizeSaga),
    takeLatest(USERS_LIST_GET, usersListSaga),
    takeLatest(USER_AWARD_POINTS, userAwardPointsSaga),
    takeLatest(USER_REVOKE_POINTS, userRevokePointsSaga),
    takeLatest(USER_GET, userGetSaga),
    takeLatest(USER_LEADERBOARD_LIST_GET, userLeaderboardSaga),
    takeLatest(USER_UPDATE, userUpdateSaga),
    takeLatest(ADD_EMAIL, addEmail),
    takeLatest(ADD_EMAIL_CHECK_VERIFICATION, addEmailCheckVerification),
    takeLatest(ADD_WALLET_ADDRESS, addWalletAddress),
    takeLatest(ADD_WALLET_ADDRESS_VERIFY_SIGN, verifyWalletAddressSaga),
    takeLatest(ADD_WALLET_ADDRESS_REJECT_SIGN, rejectWalletAddressSaga),
    takeLatest(ADD_ALTERNATE_LOGIN_VERIFY_EMAIL, alternateLoginVerifyEmail),
    takeLatest(USER_ALTERNATE_EMAIL_RESEND, resendAlternateEmail),
    takeLatest(USER_SUGGESTION_SUBMISSION, userSuggestionSubmissionSaga),
    takeLatest(GET_USER_REFERRAL_CODE, getUserReferralCode),
    takeLatest(MEMBERS_STATS_DOWNLOAD, downloadMembersStatsSaga),
  ]);
}

export default authSaga;
