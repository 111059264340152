import classNames from "classnames";
import React from "react";
import { BrandContext } from "../../../contexts/BrandContext";
import { AbbreviatedSize } from "../../../utils/size/AbbreviatedSize";

export interface Props {
  size?: AbbreviatedSize;
  initial?: boolean;
  className?: string;
}

export const TopRoundedSpinner: React.FunctionComponent<Props> = ({
  size = "sm",
  initial,
  className,
}) => {
  const brandValue = React.useContext(BrandContext);
  return (
    <div
      className={classNames(
        "animate-spin",
        "rounded-full",
        "border-t-8 border-primaryText/95",
        {
          "h-5 w-5": size === "xs",
          "h-7 w-7": size === "sm",
          "h-15 w-15": size === "md",
          "h-20 w-20": size === "lg",
          "h-25 w-25": size === "xl",
        },
        { "!border-white": initial },
        className
      )}
    />
  );
};

export default React.memo(TopRoundedSpinner);
