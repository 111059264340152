import { Item } from "../../models/entities/Item";
import { UserItem } from "../../models/entities/UserItem";
import { FilterBy, ItemStatus, SortBy } from "../../utils/enums";
import {
  ITEM_CREATE,
  ITEM_CREATE_COMPLETED,
  ITEM_CREATE_ERROR,
  ITEM_EDIT,
  ITEM_EDIT_COMPLETED,
  ITEM_EDIT_ERROR,
  ITEM_GET,
  ITEM_GET_COMPLETED,
  ITEM_GET_ERROR,
  ITEM_IMAGE_EDIT,
  ITEM_IMAGE_EDIT_COMPLETED,
  ITEM_LIST_GET,
  ITEM_LIST_GET_COMPLETED,
  ITEM_LIST_GET_ERROR,
  ITEM_LIST_OWNED_GET,
  ITEM_LIST_OWNED_GET_COMPLETED,
  ITEM_LIST_OWNED_GET_ERROR,
  ITEM_LIST_OWNED_RESET,
  ITEM_LIST_RESET,
  ITEM_PICK_WINNER,
  ITEM_PICK_WINNER_COMPLETED,
  ITEM_PICK_WINNER_ERROR,
  ITEM_REDEEM,
  ITEM_REDEEM_COMPLETED,
  ITEM_REDEEM_ERROR,
  ITEM_REDEEM_RESET,
  ITEM_REPORT_DOWNLOAD,
  ITEM_REPORT_DOWNLOAD_COMPLETED,
  RESET_APP_STATE,
} from "../actions/actions.constants";

export interface ItemState {
  imageUploading?: boolean;
  loading?: boolean;
  loaded?: boolean;
  error?: string | null;
  entities: Record<number, Item>;
  entityIds: number[];
  filters?: { sortBy?: SortBy; search?: string; filterBy?: string[] };
  ownedEntities: {
    loading?: boolean;
    loaded?: boolean;
    error?: string | null;
    ownedIds: number[];
    entities: Record<number, UserItem>;
    entityIds: number[];
    filters?: { sortBy?: SortBy; search?: string; filterBy?: string[] };
    hasMoreData: boolean;
    currentPage: number;
    metaTotalResult: number | null;
    currentId: number;
    prevPageFirstElementId: number;
  };
  currentQueryItem: {
    item: Item | undefined | null;
    loading: boolean;
    loaded: boolean;
    error: string;
  };
  hasMoreData: boolean;
  meta?: any;
  currentPage: number;
  metaTotalResult: number | null;
  currentId: number;
  isRedeemed?: boolean;
  prevPageFirstElementId: number;
  pickWinnerLoading?: boolean;
  pickWinnerLoaded?: boolean;
}

const initialState: ItemState = {
  imageUploading: false,
  loading: false,
  loaded: true,
  error: null,
  entities: {},
  entityIds: [],
  filters: {},
  ownedEntities: {
    loading: false,
    loaded: true,
    error: null,
    ownedIds: [],
    entities: {},
    entityIds: [],
    filters: {},
    hasMoreData: true,
    currentPage: 1,
    currentId: -1,
    metaTotalResult: null,
    prevPageFirstElementId: -1,
  },
  currentQueryItem: {
    item: null,
    error: "",
    loaded: true,
    loading: false,
  },
  hasMoreData: true,
  currentPage: 1,
  currentId: -1,
  metaTotalResult: null,
  prevPageFirstElementId: -1,
  meta: {},
  pickWinnerLoading: false,
  pickWinnerLoaded: false,
};

export const itemReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_APP_STATE: {
      return initialState;
    }
    case ITEM_LIST_GET:
      return {
        ...state,
        filters: action.payload.req?.query || {},
        loading: true,
        loaded: false,
      };
    case ITEM_LIST_OWNED_GET:
      return {
        ...state,
        ownedEntities: {
          ...state.ownedEntities,
          filters: action.payload.req?.query || {},
          loading: true,
          loaded: false,
        },
      };
    case ITEM_GET: {
      return {
        ...state,
        currentQueryItem: {
          ...state.currentQueryItem,
          item: undefined,
          loading: true,
          loaded: false,
          error: "",
        },
      };
    }
    case ITEM_IMAGE_EDIT:
      return {
        ...state,
        imageUploading: true,
      };
    case ITEM_IMAGE_EDIT_COMPLETED:
      const item: Item = action.payload.res.item;
      return {
        ...state,
        entities: { ...state.entities, [item.id]: item },
        imageUploading: false,
      };
    case ITEM_CREATE:
    case ITEM_EDIT:

    case ITEM_REDEEM:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case ITEM_GET_COMPLETED: {
      const item: Item = action.payload.res.item;
      return {
        ...state,
        currentQueryItem: {
          ...state.currentQueryItem,
          item: { ...item },
          loading: false,
          loaded: true,
          error: "",
        },
      };
    }
    case ITEM_GET_ERROR: {
      return {
        ...state,
        currentQueryItem: {
          ...state.currentQueryItem,
          loading: false,
          loaded: true,
          error: action.payload.message,
        },
      };
    }
    case ITEM_EDIT_COMPLETED: {
      const item: Item = action.payload.res.item;
      const filters: string[] = action.payload.res.filters;
      let newEntityIds = [...state.entityIds];
      let newEntities = { ...state.entities, [item.id]: item };
      newEntityIds = newEntityIds.filter((id) => id !== item.id);
      if (
        !filters.length ||
        filters.includes(
          (item?.status as unknown as string) === ItemStatus.PUBLISHED
            ? FilterBy.live
            : (item?.status as unknown as string)
        )
      ) {
        newEntityIds = [item.id, ...newEntityIds];
      }

      return {
        ...state,
        entities: { ...newEntities },
        entityIds: newEntityIds,
        loading: false,
        loaded: true,
        error: "",
      };
    }
    case ITEM_CREATE_COMPLETED: {
      const item: Item = action.payload.res.item;
      const filters: string[] = action.payload.filters;
      let entityIds = [...state.entityIds];

      const isFilterApplied = filters?.includes(
        (item?.status as unknown as string) === ItemStatus.PUBLISHED
          ? FilterBy.live
          : (item?.status as unknown as string)
      );
      if (!filters.length || isFilterApplied) {
        entityIds = [item.id, ...entityIds];
      }
      return {
        ...state,
        entities: { ...state.entities, [item.id]: item },
        entityIds: [...Array.from(entityIds)],
        loading: false,
        loaded: true,
        error: undefined,
      };
    }
    case ITEM_REDEEM_COMPLETED: {
      const userItem: UserItem = action.payload.res.userItem;
      const quantity = action.payload.res?.quantity;

      const ownedEntityIds = new Set(state.ownedEntities.entityIds);
      ownedEntityIds.add(userItem.id);
      return {
        ...state,
        entities: {
          ...state.entities,
          [userItem.item.id]: {
            ...state.entities[userItem.item.id],
            ...userItem.item,
            purchased_quantity: +userItem?.quantity,
          },
        },
        ownedEntities: {
          ...state.ownedEntities,
          entities: {
            ...state.ownedEntities.entities,
            [userItem.id]: userItem,
          },
          entityIds: Array.from(ownedEntityIds),
        },

        loading: false,
        loaded: true,
        error: undefined,
        isRedeemed: true,
      };
    }
    case ITEM_LIST_GET_COMPLETED: {
      const hasMoreData =
        action.payload.res.meta?.current_page <
        action.payload.res.meta?.last_page;
      const currentPage = action.payload.res.meta?.current_page;
      const firstElementOfPrevPageId = state.prevPageFirstElementId;
      const metaTotalResult = action.payload.res.meta?.total;
      const newPrevPageFirstElementId = action.payload.res.items?.length
        ? action.payload.res.items[0].id
        : state.prevPageFirstElementId;
      let entities = {
        ...state?.entities,
      };
      const entityIds = new Set(state.entityIds);
      action.payload?.res?.items?.forEach((item: Item) => {
        entityIds.add(item.id);
      });
      action.payload?.res?.items.map(
        (item: Item) => (entities = { ...entities, [item.id]: item })
      );
      return {
        ...state,
        entities,
        entityIds: Array.from(entityIds),
        hasMoreData,
        currentPage,
        metaTotalResult,
        currentId: firstElementOfPrevPageId,
        prevPageFirstElementId: newPrevPageFirstElementId,
        loading: false,
        loaded: true,
        error: undefined,
      };
    }
    case ITEM_LIST_OWNED_GET_COMPLETED: {
      const ownedIds =
        action.payload.wallet_address &&
        state.ownedEntities?.entities?.[state.ownedEntities?.ownedIds[0]]
          ?.wallet_address !== action.payload.wallet_address
          ? new Set([])
          : new Set(state.ownedEntities.ownedIds);
      const hasMoreData =
        action.payload.res.meta.current_page <
        action.payload.res.meta.last_page;
      const currentPage = action.payload.res.meta.current_page;
      const metaTotalResult = action.payload.res.meta?.total;
      const firstElementOfPrevPageId =
        state.ownedEntities.prevPageFirstElementId;
      const newPrevPageFirstElementId = action.payload.res.userItems.length
        ? action.payload.res.userItems[0].id
        : state.ownedEntities.prevPageFirstElementId;
      let entities = {
        ...state?.ownedEntities.entities,
      };
      const entityIds = new Set(state.ownedEntities.entityIds);
      action.payload.res.userItems.forEach((item: UserItem) => {
        entityIds.add(item.id);
      });
      action.payload?.res?.userItems.forEach((userItem: UserItem) => {
        ownedIds.add(userItem.id);
        entities[userItem.id] = userItem;
      });

      return {
        ...state,
        ownedEntities: {
          ...state.ownedEntities,
          ownedIds: Array.from(ownedIds),
          entities,
          entityIds: Array.from(entityIds),
          hasMoreData,
          currentPage,
          metaTotalResult,
          currentId: firstElementOfPrevPageId,
          prevPageFirstElementId: newPrevPageFirstElementId,
          loading: false,
          loaded: true,
          error: undefined,
        },
      };
    }
    case ITEM_PICK_WINNER_ERROR:
    case ITEM_CREATE_ERROR:
    case ITEM_EDIT_ERROR:
    case ITEM_LIST_GET_ERROR:
    case ITEM_REDEEM_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload?.message,
      };
    case ITEM_LIST_OWNED_GET_ERROR:
      return {
        ...state,
        ownedEntities: {
          ...state.ownedEntities,
          loading: false,
          loaded: true,
          error: action.payload?.message,
        },
      };
    case ITEM_LIST_RESET:
      return {
        ...initialState,
        ownedEntities: state.ownedEntities,
      };
    case ITEM_LIST_OWNED_RESET:
      return {
        ...state,
        ownedEntities: initialState.ownedEntities,
      };
    case ITEM_REPORT_DOWNLOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case ITEM_REPORT_DOWNLOAD_COMPLETED:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case ITEM_REDEEM_RESET: {
      return {
        ...state,
        isRedeemed: false,
        loading: false,
        loaded: true,
      };
    }

    case ITEM_PICK_WINNER: {
      return {
        ...state,
        pickWinnerLoading: true,
        pickWinnerLoaded: false,
      };
    }

    case ITEM_PICK_WINNER_COMPLETED: {
      const item: Item = action.payload.res.item;
      let entities = {
        ...state?.entities,
        [item.id]: { ...item },
      };

      const entityIds = new Set(state.entityIds);

      entityIds.add(item.id);

      return {
        ...state,
        entityIds: Array.from(entityIds),
        entities: entities,
        pickWinnerLoading: false,
        pickWinnerLoaded: true,
      };
    }

    case ITEM_PICK_WINNER_ERROR:
      return {
        ...state,
        pickWinnerLoading: false,
        pickWinnerLoaded: true,
        error: action.payload?.message,
      };
    default:
      return state;
  }
};
