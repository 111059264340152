import { getToken, isSupported } from "firebase/messaging";
import { delay } from "lodash";
import { useEffect, useState } from "react";
import { ENV_FIREBASE_PUBLIC_VAPID_KEY } from "../../constants/secret";
import { firebaseDeviceId } from "../../services/firebase/firebase";
import {
  SHOWN_NOTIFICATION_TOAST,
  webStorageService,
} from "../../services/WebStorageService";
import { messaging } from "./../../services/firebase/firebase";

const useFcmToken = (
  brandId: number | undefined | null,
  userId: number | undefined | null,
  callbackFunc: (callbackOnAccept: Function) => void
) => {
  const [token, setToken] = useState<string>("");
  const [notificationPermissionStatus, setNotificationPermissionStatus] =
    useState<string>("");
  const [deviceId, setDeviceId] = useState<string>("");

  useEffect(() => {
    let timerId: any;

    // Ask for permission and/or retrieve token
    const retrieveToken = async (messagingObj: any) => {
      try {
        // Retrieve the notification permission status
        let permission;
        if (Notification.permission === "granted") {
          permission = "granted";
        } else {
          permission = await Notification.requestPermission();
        }
        setNotificationPermissionStatus(permission);

        // Check if permission is granted before retrieving the token
        if (permission === "granted") {
          let currentToken;
          // Firebase SDK Error: Workaround - Retry multiple times.
          try {
            currentToken = await getToken(messagingObj, {
              vapidKey: ENV_FIREBASE_PUBLIC_VAPID_KEY,
            });
          } catch (e) {
            try {
              currentToken = await getToken(messagingObj, {
                vapidKey: ENV_FIREBASE_PUBLIC_VAPID_KEY,
              });
            } catch (e) {
              currentToken = await getToken(messagingObj, {
                vapidKey: ENV_FIREBASE_PUBLIC_VAPID_KEY,
              });
            }
          }
          const device_id = await firebaseDeviceId();
          if (currentToken) {
            console.log("FCM Token", currentToken, "\nDevice ID", device_id);
            setToken(currentToken);
            setDeviceId(device_id);
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        }
      } catch (error) {
        console.log("An error occurred while retrieving token:", error);
      }
    };

    if (typeof window !== "undefined" && "serviceWorker" in navigator) {
      isSupported().then((v) => {
        if (!v) {
          // If Firebase SDK not supported
          console.log("Push Notifications Not Supported!");
          return;
        } else {
          let messagingObj: any;
          try {
            messagingObj = messaging();
          } catch (e) {
            console.error(e);
            return;
          }

          // Show toast after 10 secs
          timerId = delay(() => {
            if (brandId && ![-1, undefined, null].includes(userId)) {
              const isAlreadyShown =
                webStorageService.getSessionStorageValue(
                  SHOWN_NOTIFICATION_TOAST
                ) === "true";
              if (Notification.permission === "default") {
                if (!isAlreadyShown) {
                  webStorageService.setSessionStorageValue(
                    SHOWN_NOTIFICATION_TOAST,
                    "true"
                  );
                  callbackFunc(() => retrieveToken(messagingObj));
                }
              } else {
                retrieveToken(messagingObj);
              }
            }
          }, 10000);
        }
      });
    }

    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [brandId, userId, callbackFunc]);

  return { fcmToken: token, notificationPermissionStatus, device_id: deviceId };
};

export default useFcmToken;
