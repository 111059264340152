import { delay } from "lodash";
import { useEffect, useState } from "react";
import { PWAManifestFile } from "../../models/types/PWAManifestFileType";
import {
  SHOWN_PWA_TOAST,
  webStorageService,
} from "../../services/WebStorageService";
import Utils from "../../utils/Utils";

const useShowInstallPromptInIOS = (
  callbackFunc: () => void,
  configs: {
    is_pwa_enabled: boolean;
    user_id: number;
    is_brand_id: boolean;
  },
  pwaData?: PWAManifestFile
) => {
  const [isApplePlatform, setIsApplePlatform] = useState(false);
  const [isAppDisplayStandalone, setIsAppDisplayStandalone] = useState(false);

  useEffect(() => {
    if (navigator) {
      if (Utils.isIOSDevice(navigator)) {
        setIsApplePlatform(true);
      }
    }
  }, []);

  useEffect(() => {
    if (window.matchMedia("(display-mode: standalone)").matches) {
      setIsAppDisplayStandalone(true);
    }
  }, []);

  useEffect(() => {
    if (isApplePlatform && pwaData) {
      if (
        Utils.isPWAManifestFileCorrectForIOS(pwaData) &&
        !isAppDisplayStandalone
      ) {
        const isAlreadyShown =
          webStorageService.getSessionStorageValue(SHOWN_PWA_TOAST) === "true";
        const timerId = delay(() => {
          if (
            configs.is_pwa_enabled &&
            configs.is_brand_id &&
            !isAlreadyShown
          ) {
            webStorageService.setSessionStorageValue(SHOWN_PWA_TOAST, "true");
            callbackFunc();
          }
        }, 4000);
        return () => {
          if (timerId) clearTimeout(timerId);
        };
      }
    }
  }, [
    configs.is_brand_id,
    configs.is_pwa_enabled,
    configs.user_id,
    isApplePlatform,
    pwaData,
    callbackFunc,
    isAppDisplayStandalone,
  ]);
};

export default useShowInstallPromptInIOS;
