import classNames from "classnames";
import React, { useContext } from "react";
import { BrandContext } from "../../../contexts/BrandContext";
import { BrandButtonColorStyle } from "../../../utils/enums";
import TopRoundedSpinner from "../Spinner/TopRoundedSpinner";

interface Props {
  As?: string;
  type?: "button" | "submit";
  children?: React.ReactNode;
  id?: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
  isSubmitting?: boolean;
  icon?: React.ReactNode;
  theme?: "primary" | "secondary" | "tertiary" | "tertiaryBG";
}

const BrandButton: React.FC<Props> = ({
  As = "button",
  type = "button",
  className,
  children,
  onClick,
  disabled,
  isSubmitting,
  icon,
  theme = "primary",
  ...rest
}) => {
  const brand = useContext(BrandContext);
  return (
    // @ts-ignore
    <As
      type={type}
      onClick={onClick}
      disabled={disabled}
      {...rest}
      className={classNames(
        "inline-flex relative",
        "rounded-xl",
        "justify-center items-center",
        "leading-5 font-regular-500 text-sm md:text-base",
        "transition-all ease-in-out",
        "hover:scale-105",
        "duration-300",
        "px-6 sm:px-8 py-3",
        theme === "primary" &&
          `${
            brand.brandButtonColorStyle === BrandButtonColorStyle.GRADIENT
              ? "gradient-button"
              : "bg-primaryButton"
          } text-textButton shadow-button`,
        theme === "secondary" &&
          "transparent text-primaryText border border-primaryText shadow-button",
        theme === "tertiary" &&
          "bg-primaryText text-primaryBackground border border-primaryText shadow-button",
        theme === "tertiaryBG" && "text-white shadow-button bg-white/20",
        className,
        disabled && "pointer-events-none opacity-60"
      )}
    >
      <div className={`relative flex items-center `}>
        <span
          className={`transition-all mb-0.5 ${
            isSubmitting || icon ? "icon-size-xs mr-2" : "w-0 h-0"
          }`}
        >
          {!isSubmitting && icon && <span>{icon}</span>}
          {isSubmitting && (
            <TopRoundedSpinner
              className={classNames(
                theme === "primary" && "!border-textButton",
                theme === "secondary" && "!border-primaryText",
                theme === "tertiary" && "!border-primaryBackground",
                theme === "tertiaryBG" && "!border-white"
              )}
              size="xs"
            />
          )}
        </span>
        <span className="leading-none">{children}</span>
      </div>
    </As>
  );
};

export default React.memo(BrandButton);
