import Cookies, { CookieSetOptions } from "universal-cookie";

export const AUTH_TOKEN = "auth_token";
export const GATED_TOKEN = "gatedToken";

// During Email Login
export const EMAIL_CODE = "email_code";
export const EMAIL = "email";
export const RECAPTCHA_TOKEN = "recaptcha_token";

// FCM and PWA
export const FCM_TOKEN = "fcm_token";
export const SHOWN_NOTIFICATION_TOAST = "shown_notification_toast";
export const SHOWN_PWA_TOAST = "shown_pwa_toast";

export const REFERRAL_CODE = "referralCode";
export const INVITE_ID = "inviteId";

export const COOKIE_CONSENT = "cookieConsent";

export enum COOKIE_CONSENT_KEYS {
  ESSENTIAL = "essential_storage",
  ANALYTICS = "analytics_storage",
}

export enum COOKIE_CONSENT_VALUES {
  GRANTED = "granted",
  DENIED = "denied",
}

export class WebStorageService {
  private static _instance: WebStorageService;
  private _cookies: Cookies;

  private constructor() {
    this._cookies = new Cookies();
  }

  static getInstance(): WebStorageService {
    if (!this._instance) {
      this._instance = new WebStorageService();
    }

    return this._instance;
  }

  // This method is only valid for server as the document is not defined.
  setCookies(cookies: any): void {
    this._cookies = new Cookies(cookies);
  }

  setValue(key: string, value: string, options?: CookieSetOptions): void {
    this._cookies.set(key, value, { ...options, path: "/" });
  }

  getValue(key: string): string {
    return this._cookies.get(key);
  }

  removeValue(key: string, options?: CookieSetOptions): void {
    return this._cookies.remove(key, { ...options, path: "/" });
  }

  setLocalStorageValue(key: string, value: string): void {
    if (typeof localStorage !== "undefined") {
      localStorage?.setItem(key, value);
    }
  }

  getLocalStorageValue(key: string): string | null {
    if (typeof localStorage !== "undefined") {
      return localStorage?.getItem(key);
    }
    return null;
  }

  removeLocalStorageValue(key: string): void {
    if (typeof localStorage !== "undefined") {
      localStorage?.removeItem(key);
    }
  }

  setSessionStorageValue(key: string, value: string): void {
    if (typeof sessionStorage !== "undefined") {
      sessionStorage?.setItem(key, value);
    }
  }

  getSessionStorageValue(key: string): string | null {
    if (typeof sessionStorage !== "undefined") {
      return sessionStorage?.getItem(key);
    }
    return null;
  }

  removeSessionStorageValue(key: string): void {
    if (typeof sessionStorage !== "undefined") {
      sessionStorage?.removeItem(key);
    }
  }

  async setAuthToken(token: string): Promise<void> {
    this.setValue(AUTH_TOKEN, token, {
      // 7 Days Expiry
      maxAge: 604800,
      path: "/",
    });
  }

  async getAuthToken(): Promise<string | null> {
    return this._cookies.get(AUTH_TOKEN);
  }

  async removeAuthToken(): Promise<void> {
    this._cookies.remove(AUTH_TOKEN, {
      // 7 Days Expiry
      maxAge: 604800,
      path: "/",
    });
  }

  setGatedToken(token: string): void {
    this.setValue(GATED_TOKEN, token, {
      // 7 Days Expiry
      maxAge: 604800,
      path: "/",
    });
  }

  getGatedToken() {
    return this.getValue(GATED_TOKEN);
  }

  removeGatedToken(): void {
    this._cookies.remove(GATED_TOKEN, {
      // 7 Days Expiry
      maxAge: 604800,
      path: "/",
    });
  }

  async reset(dontSkipGatedToken?: boolean): Promise<void> {
    for (const key in this._cookies.getAll()) {
      if (!dontSkipGatedToken && key === GATED_TOKEN) {
        continue;
      }
      this._cookies.remove(key);
    }
  }
}

export const webStorageService = WebStorageService.getInstance();
