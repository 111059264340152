import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { X } from "phosphor-react";
import React, { CSSProperties, Fragment, FunctionComponent } from "react";
import { BrandContext } from "../../../contexts/BrandContext";
import { BrandButtonColorStyle } from "../../../utils/enums";
import TopRoundedSpinner from "../Spinner/TopRoundedSpinner";

export interface ModalProps {
  open: boolean;
  modalStyle?: CSSProperties;
}

interface Props extends ModalProps {
  theme?: "primary" | "secondary";
  onClose: VoidFunction;
  className?: string;
  modalClassName?: string;
  parentClassNames?: string;
  overlayClassNames?: string;
  loading?: boolean;
  showCross?: boolean;
  hideScrollBar?: boolean;
  crossClassnames?: string;
  closeOnOutSideClick?: boolean;
  noModalBG?: boolean;
}

const CustomModal: FunctionComponent<Props> = ({
  open,
  onClose,
  children,
  className,
  modalClassName,
  theme = "primary",
  parentClassNames,
  overlayClassNames,
  loading,
  showCross,
  crossClassnames,
  hideScrollBar,
  closeOnOutSideClick = true,
  noModalBG,
}) => {
  const brandValue = React.useContext(BrandContext);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed z-[999] inset-0 overflow-y-auto ${className}`}
        onClose={() => {
          if (closeOnOutSideClick) {
            onClose();
          }
        }}
      >
        <div
          className={classNames(
            `flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0 ${parentClassNames} `,
            {
              " sm:flex items-center": theme === "secondary",
            }
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={`fixed inset-0 transition-opacity bg-black/80 ${overlayClassNames}`}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`relative inline-block drop-shadow-socialConnect align-middle ${
                theme === "secondary"
                  ? `rounded-tl-3xl rounded-br-3xl p-0.5 ${
                      brandValue.brandButtonColorStyle ===
                      BrandButtonColorStyle.GRADIENT
                        ? "gradient-button"
                        : "gradient-itself"
                    }`
                  : "rounded-xl"
              } ${modalClassName}`}
            >
              {showCross ? (
                <button
                  onClick={() => {
                    onClose();
                  }}
                  className={classNames(
                    "absolute top-5 right-5 z-40",
                    crossClassnames
                  )}
                >
                  <X
                    className={classNames("w-6 h-6 text-primaryText")}
                    weight="bold"
                  />
                </button>
              ) : null}

              {loading ? (
                <div
                  className={classNames(
                    "absolute inset-2 flex justify-center items-center backdrop-blur-md z-50 bg-opacity-50 !overflow-hidden ",
                    theme === "primary"
                      ? "rounded-xl"
                      : "rounded-tl-[23px] rounded-br-[23px]"
                  )}
                >
                  <TopRoundedSpinner />
                </div>
              ) : null}

              {!noModalBG ? (
                <div
                  id={"additional-div"}
                  className={classNames(
                    "px-4 md:px-10 overflow-hidden text-primaryText",
                    theme === "primary"
                      ? "bg-primaryModal/90"
                      : "bg-primaryBackground",
                    theme === "primary"
                      ? "rounded-xl"
                      : "rounded-tl-[23px] rounded-br-[23px]"
                  )}
                >
                  <div
                    className={classNames(
                      "relative text-left max-h-[70vh] sm:max-h-[85vh] overflow-auto hideScrollBar "
                    )}
                  >
                    {children}
                  </div>
                </div>
              ) : (
                <div className={modalClassName}>{children}</div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CustomModal;
