import { MessagePayload, isSupported, onMessage } from "firebase/messaging";
import React, { useRef } from "react";
import { ToastContent, toast } from "react-toastify";
import { messaging } from "../../services/firebase/firebase";

const useForegroundPushNotification = (
  toastContent: (payload: MessagePayload) => ToastContent,
  currentlyLoggedInUserId: number | undefined,
  isAdmin: boolean
) => {
  const unsubscribe = useRef<any>(null);

  React.useEffect(() => {
    const subscribe = async () => {
      if (typeof window !== "undefined" && "serviceWorker" in navigator) {
        if (!(await isSupported())) {
          // If Firebase SDK not supported
          console.log("Push Notifications Not Supported!");
          return;
        }
        let messagingObj;
        try {
          messagingObj = messaging();
        } catch (e) {
          console.error(e);
          return;
        }

        const unsubscribeObj = onMessage(messagingObj, (payload) => {
          console.log("Got it");
          if (
            payload?.data?.["admin_id"] === `${currentlyLoggedInUserId}` &&
            isAdmin
          ) {
            return;
          }
          console.log("Foreground push notification received:", payload);
          // Handle the received push notification while the app is in the foreground
          toast(toastContent(payload), {
            closeOnClick: false,
            closeButton: true,
          });
        });
        unsubscribe.current = unsubscribeObj;
      }
    };
    subscribe();
  }, [toastContent, currentlyLoggedInUserId, isAdmin]);

  React.useEffect(() => {
    return () => {
      if (unsubscribe.current) {
        unsubscribe.current(); // Unsubscribe from the onMessage event
      }
    };
  }, []);
};

export default useForegroundPushNotification;
