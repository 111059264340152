import { Roles } from "../src/utils/Roles.enums";
import { Providers } from "../src/utils/enums";

export const matchPagePathRegex = (baseRoute: string) => {
  // regex for: /baseRoute, its subroutes, hash fragments and query params
  return new RegExp(
    `^\\${baseRoute}(?:\\/[a-zA-Z0-9-_]+)*(?:#[a-zA-Z0-9-_]+)?(?:\\?.*)?$`
  );
};

/**
 * Convention:
 * 1. Have separate key-value pair for each user role
 * 2. Keep the key name in blocks, if it has an object as value (non-leaf node)
 * 3. Keep leaf key-value pair and functions in small letters [Leaf value= string or a function that returns string]
 * 4. If it's a non-leaf value, always have a key named "META" with regex of the whole category (For dashboard component)
 *  */
const NextRouteConfig = {
  // No Roles defined, as such
  _404: "404",
  _slash: "/",
  logout: "/logout",
  login: "/login",
  "gated-entry": "/gated-entry",
  "terms-and-conditions": "/terms-and-conditions",
  "privacy-policy": "/privacy-policy",
  VERIFY_EMAIL: {
    _root: "/auth/login/verify-email",
    verify_code: (code: string) => `/auth/login/verify-email?code=${code}`,
    META: {
      regex: matchPagePathRegex("/auth/login/verify-email"),
    },
  },
  ALT_LOGIN_VERIFY_EMAIL: {
    _root: "/alternate-login/verify-email",
    verify_code: (code: string) => `/alternate-login/verify-email?code=${code}`,
    META: {
      regex: matchPagePathRegex("/alternate-login/verify-email"),
    },
  },
  USER_INVITE: {
    _root: "/user-invite",
    invite_id: (invite_id: string) => `/user-invite/?invite_id=${invite_id}`,
    referral_code: (referral_code: string) =>
      `/user-invite/?referral_code=${referral_code}`,
    META: {
      regex: matchPagePathRegex("/user-invite"),
    },
  },

  // Public Role
  PUBLIC: {
    SHARE_REWARD: {
      share_reward: (reward_id: number) => `/public/rewards/${reward_id}`,
      META: {
        regex: matchPagePathRegex("/public/rewards/"),
      },
    },

    SHARE_QUEST: {
      share_quest: (quest_id: number) => `/public/quests/${quest_id}`,
      META: {
        regex: matchPagePathRegex("/public/quests/"),
      },
    },

    SHARE_EXCLUSIVE_CONTENT: {
      share_exclusive_content: (content_id: number) =>
        `/public/exclusive-content/${content_id}`,
      META: {
        regex: matchPagePathRegex("/public/exclusive-content"),
      },
    },
  },

  // User Role
  USER: {
    HOME: {
      _root: "/home",
      platform: (socialPlatform: Providers) =>
        `/home?provider=${socialPlatform}`,
      share_quest: (quest_id: number) =>
        `/home?provider=quest&share-quest=${quest_id}`,
      socials_token: "/home/socials/token",
      META: {
        regex: matchPagePathRegex("/home"),
      },
    },

    EXCLUSIVE_CONTENT: {
      _root: "/exclusive-content",
      share_exclusive_content: (id: number) =>
        `/exclusive-content?share-exclusive-content=${id}`,
      META: {
        regex: matchPagePathRegex("/exclusive-content"),
      },
    },
    REWARDS: {
      _root: "/rewards",
      share_item: (id: number) => `/rewards?share-item=${id}`,
      META: {
        regex: matchPagePathRegex("/rewards"),
      },
    },
    ACHIEVEMENTS: {
      _root: "/achievements",
      META: {
        regex: matchPagePathRegex("/achievements"),
      },
    },
    SETTINGS: {
      _root: "/settings",
      my_activity: (provider: Providers) =>
        `/settings?my_activity_provider=${provider}`,
      META: {
        regex: matchPagePathRegex("/settings"),
      },
    },
  },

  // Admin Role
  ADMIN: {
    CAMPAIGNS: {
      _root: "/admin/campaigns",
      _view_meta: (params: { quest_id?: number; show_claims?: boolean }) => {
        let route = NextRouteConfig.ADMIN.CAMPAIGNS._root;
        let p = `?`;
        const keys = Object.keys(params);
        keys.forEach((params_key, i) => {
          p =
            p +
            `${params_key}=${params[params_key as keyof typeof params]}` +
            `${i !== keys.length - 1 ? `&` : ""}`;
        });
        return route + p;
      },
      META: {
        regex: matchPagePathRegex("/admin/campaigns"),
      },
    },
    REWARDS: {
      _root: "/admin/rewards",
      query_item: (id: number) => `/admin/rewards?item=${id}`,
      META: {
        regex: matchPagePathRegex("/admin/rewards"),
      },
    },
    EXCLUSIVE_CONTENT: {
      _root: "/admin/exclusive-content",
      query_exclusive_content: (id: number) =>
        `/admin/exclusive-content?share-exclusive-content=${id}`,
      META: {
        regex: matchPagePathRegex("/admin/exclusive-content"),
      },
    },
    ANALYTICS: {
      _root: "/admin/analytics",
      META: {
        regex: matchPagePathRegex("/admin/analytics"),
      },
    },
    TRANSACTIONS: {
      _root: "/admin/transactions",
      META: {
        regex: matchPagePathRegex("/admin/transactions"),
      },
    },
    MEMBERS: {
      _root: "/admin/members",
      META: {
        regex: matchPagePathRegex("/admin/members"),
      },
    },
    SETTINGS: {
      _root: "/admin/settings",
      tab: (tab: AdminSettingsSubRoutes) => `/admin/settings?tab=${tab}`,
      META: {
        regex: matchPagePathRegex("/admin/settings"),
      },
    },
  },
};

export enum AdminSettingsSubRoutes {
  ACCOUNT = "account",
  LOGIN_SECURITY = "login-security",
  INTEGRATIONS = "integrations",
  APPEARANCE = "appearance",
}

export const getDefaultRoute = (userType: Roles): string => {
  switch (userType) {
    case Roles.USER:
      return NextRouteConfig.USER.HOME._root;
    case Roles.ADMIN:
      return NextRouteConfig.ADMIN.CAMPAIGNS._root;
    default:
      return "/";
  }
};

export default NextRouteConfig;
