import { omit } from "lodash";
import { BrandMetadata } from "../../models/entities/Brand";
import Quest from "../../models/entities/Quest";
import { Learn2EarnContentRequestPayloadType } from "../../models/entities/quest/lear2earn/Learn2Earn";
import UserQuest from "../../models/entities/user-quest/UserQuest";
import { QuestCategory, SortBy } from "../../utils/enums";
import { webStorageService } from "../WebStorageService";
import { baseApiService } from "./BaseApiService";

class QuestService {
  static getInstance(): QuestService {
    return new QuestService();
  }
  async list(payload: {
    filter: string[];
    sort_by: string;
    search?: string;
    category?: string[];
    page?: any;
  }): Promise<{
    quests: Quest[];
    meta: any;
  }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get("/quests", {
      extras: {
        useAuth: authToken ? true : false,
      },
      params: {
        page: payload.page.toString(),
        filters: payload.filter ?? [],
        sort_by: payload.sort_by,
        search: payload.search ?? "",
        category: payload.category ?? [],
      },
    });
    return {
      quests: response.data.quests,
      meta: response.data.meta,
    };
  }
  async create(payload: any): Promise<{
    quest: Quest[];
    signedUrl: string;
    video_signed_urls?: any;
    isPushNotificationNotSent?: boolean;
  }> {
    const handelLearn2EarnMeta = (meta: {
      subType: string;
      quest_content: Learn2EarnContentRequestPayloadType[];
    }) => {
      return {
        subType: meta?.subType,
        quest_content: meta.quest_content.reduce(
          (accumulator: any, content) => {
            return [...accumulator, omit(content, "video_file")];
          },
          []
        ),
      };
    };
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.post(
      "/quests",
      {
        ...payload,
        meta:
          payload.type === QuestCategory.LEARN_2_EARN
            ? handelLearn2EarnMeta(payload?.meta)
            : payload?.meta,
      },
      {
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );
    return {
      quest: response.data,
      signedUrl: response.signed_url,
      video_signed_urls: response.video_signed_urls,
      isPushNotificationNotSent: response.isPushNotificationNotSent,
    };
  }
  async update(payload: any): Promise<{
    quest: Quest;
    video_signed_urls?: any;
    isPushNotificationNotSent?: boolean;
  }> {
    const handelLearn2EarnMeta = (meta: {
      subType: string;
      quest_content: Learn2EarnContentRequestPayloadType[];
    }) => {
      return {
        subType: meta?.subType,
        quest_content: (
          meta.quest_content as Learn2EarnContentRequestPayloadType[]
        ).reduce((accumulator: any, content) => {
          return [...accumulator, omit(content, "video_file")];
        }, []),
      };
    };
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.put(
      `/quests/${payload?.id}`,
      {
        ...payload,
        meta:
          payload.type === QuestCategory.LEARN_2_EARN
            ? handelLearn2EarnMeta(payload?.meta)
            : payload?.meta,
      },
      {
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );

    return {
      quest: response.data,
      video_signed_urls: response.video_signed_urls,
      isPushNotificationNotSent: response?.isPushNotificationNotSent,
    };
  }
  async coverImageUpdate(
    id: number
  ): Promise<{ quest: Quest; signedUrl: string }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.put(
      `/quests/${id}/image`,
      {},
      {
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );

    return { quest: response.data, signedUrl: response.signed_url };
  }

  async get(id: number): Promise<{ quest: Quest }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get("/quests/" + id, {
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    const quest: Quest = {
      ...response.data.quest,
      claimed_quantity: response.data.claimedAmountByUser,
    };
    return { quest };
  }

  async metadata(payload: {
    id: number;
  }): Promise<{ metadata: BrandMetadata }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get("quests/metadata/" + payload.id, {
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return { metadata: (response as any).data };
  }

  //user-quest-services:

  async myActivityList(payload: {
    page: number;
    filters: string[];
    sort_by: SortBy;
  }): Promise<{
    user_quests: UserQuest[];
    meta: any;
  }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get("/user-quests/my-activity", {
      params: {
        category: payload.filters ?? [],
        sort_by: payload.sort_by ?? "",
        page: payload?.page.toString() || "",
      },
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return {
      user_quests: response.data.user_quests,
      meta: response.data.meta,
    };
  }

  async claim(payload: {
    quest_id: number;
    req: any;
  }): Promise<{ quest: Quest[]; signedUrls?: any }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.post(
      `/user-quests/${payload.quest_id}`,
      {
        quest_data: { ...payload?.req },
      },
      {
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );
    return {
      quest: response.data.user_quest,
      signedUrls: response.data.mediaSignedUrls,
    };
  }

  async userList(payload: {
    filter: string[];
    sort_by: string;
    search?: string;
    category?: string[];
    // page?: any;
  }): Promise<{
    quests: Quest[];
    meta: any;
  }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get("/user-quests", {
      extras: {
        useAuth: authToken ? true : false,
      },
      params: {
        // page: payload.page.toString(),
        filters: payload.filter ?? [],
        sort_by: payload.sort_by,
        search: payload.search ?? "",
        category: payload.category ?? [],
      },
    });
    const quests = (response.data.quests as any[]).reduce<Quest[]>(
      (accumulator: any, quest) => {
        return [
          ...accumulator,
          {
            ...omit(quest, "claimedAmountByUser"),
            claimed_quantity: quest?.claimedAmountByUser,
          },
        ];
      },
      []
    );
    return {
      quests,
      meta: response.data.meta,
    };
  }

  async listAllUserQuest(payload: {
    quest_id?: number;
    filter: string[];
    search?: string;
    category?: string[];
    page?: any;
  }): Promise<{
    quests: Quest[];
    meta: any;
  }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get("/user-quests/list-all", {
      extras: {
        useAuth: authToken ? true : false,
      },

      params: {
        quest_id: payload.quest_id?.toString(),
        page: payload.page.toString(),
        filters: payload.filter ?? [],
        search: payload.search ?? "",
        category: payload.category ?? [],
      },
    });

    return response.data;
  }

  async updateUserQuestStatus(payload: {
    userQuestId: number;
    status: string;
    comment?: string;
  }): Promise<{ updatedQuest: UserQuest }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.put(
      `/user-quests/status/${payload.userQuestId}`,
      {
        status: payload.status,
        admin_comment: payload?.comment || undefined,
      },
      {
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );

    return {
      updatedQuest: response.data.user_quest,
    };
  }

  async approveOrRejectAllUserQuest(payload: {
    quest_id?: number;
    status: string;
    comment?: string;
    query: {
      search?: string | null;
      category?: string[];
    };
  }): Promise<{ updatedQuests: UserQuest }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.put(
      `/user-quests/approve-or-reject-all`,
      {
        quest_id: payload.quest_id,
        status: payload.status,
        admin_comment: payload?.comment || undefined,
        search: payload.query?.search ?? "",
        category: payload.query?.category ?? [],
      },
      {
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );

    return {
      updatedQuests: response.data.user_quests,
    };
  }
}

export const questService = QuestService.getInstance();
