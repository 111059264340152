import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { RewardRedeemedPayloadGA } from "../../models/types/GAPayload.types";
import { SAGA_PAYLOAD_TYPE } from "../../models/types/SagaPayloadType";
import { webToastService } from "../../services/WebToastService";
import { baseApiService } from "../../services/api-services/BaseApiService";
import { itemService } from "../../services/api-services/ItemService";
import Utils from "../../utils/Utils";
import { GAEventName } from "../../utils/enums";
import {
  ITEM_CREATE,
  ITEM_EDIT,
  ITEM_GET,
  ITEM_IMAGE_EDIT,
  ITEM_LIST_GET,
  ITEM_LIST_OWNED_GET,
  ITEM_PICK_WINNER,
  ITEM_REDEEM,
  ITEM_REPORT_DOWNLOAD,
  ITEM_STATS_DOWNLOAD,
  ITEM_STATS_LIST,
} from "../actions/actions.constants";
import {
  itemCreateCompletedAction,
  itemCreateErrorAction,
  itemEditCompletedAction,
  itemEditErrorAction,
  itemGetCompletedAction,
  itemGetErrorAction,
  itemImageEditCompletedAction,
  itemImageEditErrorAction,
  itemListGetCompletedAction,
  itemListGetErrorAction,
  itemListOwnedGetCompletedAction,
  itemListOwnedGetErrorAction,
  itemPickWinner,
  itemPickWinnerCompletedAction,
  itemPickWinnerErrorAction,
  itemRedeemCompletedAction,
  itemRedeemErrorAction,
  itemReportDownloadCompletedAction,
  itemStatsDownloadCompletedAction,
  itemStatsListCompletedAction,
  itemStatsListErrorAction,
} from "../actions/item.actions";
import { AppState } from "../reducers";
import { ItemState } from "../reducers/item.reducer";

function* getItemSaga(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(itemService.get, data.payload);
    yield put(
      itemGetCompletedAction({
        res: {
          item: response.item,
        },
      })
    );
  } catch (e: any) {
    yield put(
      itemGetErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* getItemStatsSaga(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(itemService.listItemStats, data.payload);
    yield put(
      itemStatsListCompletedAction({
        res: {
          item_stats: response.item_stats,
          id: data.payload.id,
          meta: response.meta,
        },
      })
    );
  } catch (e: any) {
    yield put(
      itemStatsListErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* downloadItemStatsSaga(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(itemService.downloadItemStats, data.payload);
    yield put(itemStatsDownloadCompletedAction());
    webToastService.showSuccess("File Downloaded Successfully");
    window.open(response.url, "_blank", "noopener noreferrer");
  } catch (e: any) {
    webToastService.showError(
      (e?.errors && e.errors[0]?.message) || e?.message
    );
  }
}

function* createItemSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(itemService.create, payload.req);
    yield call(
      baseApiService.uploadFileToS3UsingObjectURL,
      payload.fileObjectUrl,
      response.signedUrl
    );

    if (response?.isPushNotificationNotSent) {
      webToastService.showWarning(
        "Successfully created but push notifications not sent."
      );
    } else {
      webToastService.showSuccess("Successfully Created");
    }
    yield put(
      itemCreateCompletedAction({
        filters: payload.filters,
        res: { item: response.item },
      })
    );
  } catch (e: any) {
    yield put(
      itemCreateErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* editItemImageSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(itemService.editImage, payload.id);
    yield call(
      baseApiService.uploadFileToS3UsingObjectURL,
      payload.fileObjectUrl,
      response.signedUrl
    );
    webToastService.showSuccess("Successfully Uploaded New Image");
    yield put(itemImageEditCompletedAction({ res: { item: response.item } }));
  } catch (e: any) {
    yield put(
      itemImageEditErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* editItemSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(itemService.edit, payload.id, payload.req);

    if (response?.isPushNotificationNotSent) {
      webToastService.showWarning(
        "Successfully Edited but push notifications not sent."
      );
    } else {
      webToastService.showSuccess("Successfully Edited");
    }

    yield put(
      itemEditCompletedAction({
        res: { item: response.item, filters: payload.filters },
      })
    );
  } catch (e: any) {
    yield put(
      itemEditErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* getItemListSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(itemService.list, payload.req);
    yield put(
      itemListGetCompletedAction({
        res: { items: response.items, meta: response.meta },
      })
    );
  } catch (e: any) {
    yield put(
      itemListGetErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* getItemOwnedListSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(itemService.listOwned, payload);
    yield put(
      itemListOwnedGetCompletedAction({
        wallet_address: data.payload.wallet_address,
        res: { userItems: response.userItems, meta: response.meta },
      })
    );
  } catch (e: any) {
    yield put(
      itemListOwnedGetErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* redeemItemSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(itemService.redeem, payload.id, payload.req);
    webToastService.showSuccess("Successfully Redeemed!");
    yield put(
      itemRedeemCompletedAction({
        res: { userItem: response.userItem, quantity: payload?.req?.quantity },
      })
    );
    const state: ItemState = yield select((state: AppState) => state.item);

    Utils.GAEvent<RewardRedeemedPayloadGA>(GAEventName.REWARD_REDEEM, {
      event_category: "Rewards",
      event_label: `Rewards Redeemed -  ${
        state.entities[payload.id].title ?? "Unknown"
      }`,
    });
  } catch (e: any) {
    const message = (e?.errors && e.errors[0]?.message) || e?.message;
    yield put(
      itemRedeemErrorAction({
        message,
      })
    );
  }
}

function* downloadItemReportSaga(data: SAGA_PAYLOAD_TYPE): any {
  try {
    const response = yield call(itemService.downloadItemReport);
    yield put(itemReportDownloadCompletedAction());
    if (response.url) {
      webToastService.showSuccess("Report Downloaded Successfully");
      window.open(response.url, "_blank", "noopener noreferrer");
    }
  } catch (e: any) {
    webToastService.showError("Something went wrong.");
  }
}

function* pickWinner(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(itemService.pickWinner, payload);
    yield put(
      itemPickWinnerCompletedAction({
        res: {
          item: response.item,
          user: response.user,
        },
      })
    );
  } catch (e: any) {
    yield put(
      itemPickWinnerErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* itemSaga() {
  yield all([
    takeLatest(ITEM_GET, getItemSaga),
    takeLatest(ITEM_CREATE, createItemSaga),
    takeLatest(ITEM_IMAGE_EDIT, editItemImageSaga),
    takeLatest(ITEM_EDIT, editItemSaga),
    takeLatest(ITEM_LIST_GET, getItemListSaga),
    takeLatest(ITEM_LIST_OWNED_GET, getItemOwnedListSaga),
    takeLatest(ITEM_REDEEM, redeemItemSaga),
    takeLatest(ITEM_STATS_LIST, getItemStatsSaga),
    takeLatest(ITEM_STATS_DOWNLOAD, downloadItemStatsSaga),
    takeLatest(ITEM_REPORT_DOWNLOAD, downloadItemReportSaga),
    takeLatest(ITEM_PICK_WINNER, pickWinner),
  ]);
}

export default itemSaga;
