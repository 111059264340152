import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { QuestClaimedPayloadGA } from "../../models/types/GAPayload.types";
import { SAGA_PAYLOAD_TYPE } from "../../models/types/SagaPayloadType";
import { webToastService } from "../../services/WebToastService";
import { baseApiService } from "../../services/api-services/BaseApiService";
import { questService } from "../../services/api-services/QuestServices";
import Utils from "../../utils/Utils";
import {
  APIErrorCode,
  GAEventName,
  ProofSubType,
  QuestCategory,
  UserQuestStatus,
} from "../../utils/enums";
import {
  APPROVE_OR_REJECT_ALL_USER_QUEST,
  CLAIM_USER_QUEST,
  LIST_ALL_USER_QUEST,
  MY_QUESTS_ACTIVITY_LIST_GET,
  QUESTS_LIST_GET,
  QUEST_COVER_IMAGE_UPDATE,
  QUEST_CREATE,
  QUEST_GET,
  QUEST_UPDATE,
  UPDATE_USER_QUEST_STATUS,
  USER_QUESTS_LIST_GET,
} from "../actions/actions.constants";
import {
  createNewQuestCompletedAction,
  createNewQuestErrorAction,
  questCoverImageUpdateCompletedAction,
  questCoverImageUpdateErrorAction,
  questGetCompletedAction,
  questGetErrorAction,
  questUpdateCompletedAction,
  questUpdateErrorAction,
  questsListGetCompletedAction,
  questsListGetErrorAction,
} from "../actions/quest.actions";
import { adminIsQuestApprovalPending } from "../actions/user.actions";
import {
  approveOrRejectAllUserQuestCompletedAction,
  approveOrRejectAllUserQuestErrorAction,
  claimUserQuestCompletedAction,
  isQuestApprovalForSelectedQuestPendingAction,
  listAllUserQuestsGetAction,
  myQuestsActivityListGetCompletedAction,
  myQuestsActivityListGetErrorAction,
  userQuestsListGetCompletedAction,
  userQuestsListGetErrorAction,
} from "../actions/user.quest.action";
import { AppState } from "../reducers";
import { QuestState } from "../reducers/quest.reducer";
import {
  claimUserQuestErrorAction,
  listAllUserQuestsCompletedAction,
  listAllUserQuestsGetErrorAction,
  updateUserQuestStatusCompletedAction,
  updateUserQuestStatusErrorAction,
} from "./../actions/user.quest.action";

function* questsListSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(questService.list, {
      filter: payload?.req?.query?.filter,
      sort_by: payload?.req?.query?.sort_by,
      search: payload?.req?.query?.search,
      category: payload.req?.query?.category,
      page: payload?.req?.page,
    });
    yield put(
      questsListGetCompletedAction({
        res: {
          quests: response.quests,
          meta: response?.meta,
        },
      })
    );
  } catch (e: any) {
    yield put(
      questsListGetErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}
function* createQuestSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(questService.create, {
      ...payload.req,
      is_cover_uploaded: !!payload?.cover_image,
    });
    if (!!payload?.cover_image) {
      yield call(
        baseApiService.uploadFileToS3UsingObjectURL,
        payload?.cover_image,
        response.signedUrl
      );
    }
    if (payload?.req.type === QuestCategory.LEARN_2_EARN) {
      for (let content of payload.req.meta.quest_content as any[]) {
        if (content.upload_video) {
          yield call(
            baseApiService.uploadFileToS3UsingObjectURL,
            content?.video_file,
            response.video_signed_urls[content?.id]
          );
        }
      }
    }

    yield put(
      createNewQuestCompletedAction({
        res: {
          quest: response.quest,
        },
      })
    );
    if (response?.isPushNotificationNotSent) {
      webToastService.showWarning(
        "Successfully created but push notifications not sent."
      );
    } else {
      webToastService.showSuccess("Quest added successfully");
    }
  } catch (e: any) {
    yield put(
      createNewQuestErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* updateQuestSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(questService.update, payload.req);
    if (payload?.req.type === QuestCategory.LEARN_2_EARN) {
      for (let content of payload.req.meta.quest_content as any[]) {
        if (content.upload_video) {
          yield call(
            baseApiService.uploadFileToS3UsingObjectURL,
            content?.video_file,
            response.video_signed_urls[content?.id]
          );
        }
      }
    }
    yield put(
      questUpdateCompletedAction({
        res: {
          quest: response.quest,
        },
      })
    );
    if (response?.isPushNotificationNotSent) {
      webToastService.showWarning(
        "Successfully updated but push notifications not sent."
      );
    } else {
      webToastService.showSuccess("Quest updated successfully");
    }
  } catch (e: any) {
    yield put(
      questUpdateErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* updateCoverImageSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(questService.coverImageUpdate, payload.req?.id);
    yield call(
      baseApiService.uploadFileToS3UsingObjectURL,
      payload?.req?.cover_image,
      response.signedUrl
    );

    yield put(
      questCoverImageUpdateCompletedAction({
        res: {
          quest: response.quest,
        },
      })
    );
    webToastService.showSuccess("Quest Image is updated successfully");
  } catch (e: any) {
    yield put(
      questCoverImageUpdateErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* questGetSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(questService.get, payload.req.id);
    yield put(
      questGetCompletedAction({
        res: {
          quest: response.quest,
        },
      })
    );
  } catch (e: any) {
    yield put(
      questGetErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

//user-quest-sagas:
function* listAllUserQuests(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(questService.listAllUserQuest, {
      quest_id: payload?.req?.quest_id,
      filter: payload?.req?.query?.filter,
      search: payload?.req?.query?.search,
      category: payload.req?.query?.category,
      page: payload?.req?.page,
    });
    yield put(
      listAllUserQuestsCompletedAction({
        res: {
          quests: response?.user_quests,
          meta: response?.meta,
        },
      })
    );
    const is_quest_approval_pending = response?.user_quests.some(
      (u: any) => u.status === UserQuestStatus.PENDING
    );
    yield put(
      adminIsQuestApprovalPending({ res: { is_quest_approval_pending } })
    );
  } catch (e: any) {
    yield put(
      listAllUserQuestsGetErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* userQuestsListGetSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(questService.userList, {
      filter: payload?.req?.query?.filter,
      sort_by: payload?.req?.query?.sort_by,
      search: payload?.req?.query?.search,
      category: payload.req?.query?.category,
      // page: payload?.req?.page,
    });
    yield put(
      userQuestsListGetCompletedAction({
        res: {
          quests: response.quests,
          meta: response?.meta,
        },
      })
    );
  } catch (e: any) {
    yield put(
      userQuestsListGetErrorAction({
        message: (e?.errors && e.errors[0]?.message) || e?.message,
      })
    );
  }
}

function* myQuestsActivityListGetSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(questService.myActivityList, {
      page: payload?.page,
      ...payload.req,
    });
    yield put(
      myQuestsActivityListGetCompletedAction({
        res: {
          user_quests: response.user_quests,
          meta: response?.meta,
        },
      })
    );
  } catch (e: any) {
    yield put(
      myQuestsActivityListGetErrorAction({
        message:
          (e?.errors && e.errors[0]?.message) ||
          e?.response?.data.message ||
          e?.message,
      })
    );
  }
}

function* claimQuestSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(questService.claim, payload);

    if (
      payload.type === QuestCategory.PROOF &&
      payload?.req?.subType === ProofSubType.MEDIA
    ) {
      for (let content of payload?.mediaObjectUrls as any[]) {
        yield call(
          baseApiService.uploadFileToS3UsingObjectURL,
          content.value,
          response.signedUrls[content?.proof_id].presignedUrl
        );
      }
    }

    const state: QuestState = yield select((state: AppState) => state?.quest);
    const quest = state.entities[payload?.quest_id];
    Utils.GAEvent<QuestClaimedPayloadGA>(GAEventName.QUEST_CLAIM, {
      event_category: "Quests",
      event_label: `Quest Claimed - ${quest?.title ?? "Quest"}`,
    });

    yield put(
      claimUserQuestCompletedAction({
        res: {
          quest: response.quest,
        },
      })
    );
  } catch (e: any) {
    yield put(
      claimUserQuestErrorAction({
        message:
          (e?.errors && e.errors[0]?.message) ||
          e?.response?.data.message ||
          e?.message,
        isTryAgainQuest:
          e?.response.data.code ===
            APIErrorCode.USER_QUIZ_QUEST_INCORRECT_ANSWER ||
          e?.response.data.code === APIErrorCode.E_INCORRECT_ANSWER
            ? true
            : undefined,
        isSafetyCheckLinkError:
          e?.response.data.code === APIErrorCode.SAFETY_CHECK_LINK_FAILED,
      })
    );
  }
}

function* updateUserQuestStatusSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;

  try {
    const response = yield call(
      questService.updateUserQuestStatus,
      payload.req
    );
    yield put(
      updateUserQuestStatusCompletedAction({
        res: {
          updatedQuest: response.updatedQuest,
        },
      })
    );
    webToastService.showSuccess("Quest Updated successfully");
    const state: QuestState = yield select((state: AppState) => state.quest);
    const userQuestApprovalEntities =
      state.userQuestsAprrovalEntities?.entities;
    const userQuestApprovalEntityIDs =
      state.userQuestsAprrovalEntities?.entityIds;

    let isQuestApprovalForSelectedQuestPending = false;

    if (data.payload?.req?.questId) {
      const filteredUserQuest = userQuestApprovalEntityIDs.filter(
        (id) =>
          userQuestApprovalEntities[id]?.quest_id === data.payload?.req?.questId
      );
      isQuestApprovalForSelectedQuestPending = filteredUserQuest.some(
        (id) => userQuestApprovalEntities[id].status === UserQuestStatus.PENDING
      );
    } else {
      isQuestApprovalForSelectedQuestPending = userQuestApprovalEntityIDs.some(
        (id) => userQuestApprovalEntities[id].status === UserQuestStatus.PENDING
      );
    }

    if (!isQuestApprovalForSelectedQuestPending) {
      yield put(
        isQuestApprovalForSelectedQuestPendingAction({
          res: { is_pending: false },
        })
      );
    }
    const is_quest_approval_pending = Object.values(
      userQuestApprovalEntities
    ).some((u: any) => u.status === UserQuestStatus.PENDING);
    yield put(
      adminIsQuestApprovalPending({ res: { is_quest_approval_pending } })
    );
    yield put(
      listAllUserQuestsGetAction({
        req: {
          page: payload?.req.query?.page,
          quest_id: payload?.req.questId,
          query: {
            category: payload?.req.query?.category,
            filter: payload?.req.query?.filter,
            search: payload?.req.query?.search,
          },
        },
      })
    );
  } catch (e: any) {
    yield put(
      updateUserQuestStatusErrorAction({
        message:
          (e?.errors && e.errors[0]?.message) ||
          e?.response?.data.message ||
          e?.message,
      })
    );
  }
}

function* approveAllQuestSaga(data: SAGA_PAYLOAD_TYPE): any {
  const payload = data.payload;
  try {
    const response = yield call(
      questService.approveOrRejectAllUserQuest,
      payload.req
    );
    yield put(
      approveOrRejectAllUserQuestCompletedAction({
        res: { user_quests: response.updatedQuests },
      })
    );
    webToastService.showSuccess("Quest Updated successfully");
    yield put(
      isQuestApprovalForSelectedQuestPendingAction({
        res: { is_pending: false },
      })
    );
    const state: QuestState = yield select((state: AppState) => state.quest);
    const is_quest_approval_pending = Object.values(
      state.userQuestsAprrovalEntities?.entities
    ).some((u: any) => u.status === UserQuestStatus.PENDING);

    yield put(
      adminIsQuestApprovalPending({
        res: { is_quest_approval_pending },
      })
    );
  } catch (e: any) {
    yield put(
      approveOrRejectAllUserQuestErrorAction({
        message:
          (e?.errors && e.errors[0]?.message) ||
          e?.response?.data.message ||
          e?.message,
      })
    );
  }
}

function* questSaga() {
  yield all([
    takeLatest(QUEST_CREATE, createQuestSaga),
    takeLatest(QUESTS_LIST_GET, questsListSaga),
    takeLatest(QUEST_UPDATE, updateQuestSaga),
    takeLatest(QUEST_COVER_IMAGE_UPDATE, updateCoverImageSaga),
    takeLatest(QUEST_GET, questGetSaga),

    //user-quest-sagas:
    takeLatest(LIST_ALL_USER_QUEST, listAllUserQuests),
    takeLatest(USER_QUESTS_LIST_GET, userQuestsListGetSaga),
    takeLatest(MY_QUESTS_ACTIVITY_LIST_GET, myQuestsActivityListGetSaga),
    takeLatest(CLAIM_USER_QUEST, claimQuestSaga),
    takeLatest(UPDATE_USER_QUEST_STATUS, updateUserQuestStatusSaga),
    takeLatest(APPROVE_OR_REJECT_ALL_USER_QUEST, approveAllQuestSaga),
  ]);
}

export default questSaga;
